import React, { useMemo } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { useNavigate } from 'react-router'
import { themeVariables } from 'themes/themeVariables'
import { useAuth } from 'providers/AuthProvider'
import { useTeamSelection } from 'utils/useTeamSelection'
import { useCurrentUserQuery } from 'utils/queries'
import { Dropdown, DropdownContent } from './Dropdown'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from './Form/Select/SelectItemRenderer'
import { Icon } from './Icon'
import { Logo } from './Logo'

import { route } from '../utils/routes'
import { Separator } from './Separator'

const LogoContainer = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
`

const LogoChevron = styled(Icon)<{ $isOpen: boolean }>`
    color: ${themeVariables.colors.primary};
    transform: ${({ $isOpen }) =>
        $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 0.2s ease;
`

const StyledLogo = styled(Logo)`
    border-radius: 4px;
`

const StyledSeparator = styled(Separator)`
    margin: 8px 0;
`

type OrganizationStub = {
    id: string
    name: string
    teams: {
        id: string
        name: string
    }[]
}

export const SidebarDropdownMenu = () => {
    const navigate = useNavigate()
    const { logout } = useAuth()
    const { data: user } = useCurrentUserQuery()
    const { currentOrganization, switchTeam } = useTeamSelection()

    const userOrgs: OrganizationStub[] = useMemo(() => {
        if (!user || !user.teamRoles) return []
        const organizationMap = new Map<string, OrganizationStub>()

        user.teamRoles.forEach((teamRole) => {
            let org = organizationMap.get(teamRole.team.organizationId)
            if (!org) {
                org = {
                    ...teamRole.team.organization,
                    teams: [teamRole.team],
                }
                organizationMap.set(teamRole.team.organizationId, org)
            } else {
                org.teams.push(teamRole.team)
            }
        })
        return Array.from(organizationMap.values()).sort((a, b) =>
            a.name.localeCompare(b.name)
        )
    }, [user, user?.teamRoles])

    const userIsOwnerInCurrentOrg: boolean = useMemo(() => {
        if (!user || !user.organizationRoles || !currentOrganization)
            return false

        return (
            user.organizationRoles.find(
                (orgRole) =>
                    orgRole.organizationId === currentOrganization.id &&
                    orgRole.role === 'Owner'
            ) !== undefined
        )
    }, [user, user?.organizationRoles, currentOrganization])

    return (
        <Dropdown
            trigger={({ onClick, isOpen }) => (
                <LogoContainer onClick={onClick}>
                    <StyledLogo size="small" />
                    <span className={cx('large', 'emphasized')}>
                        {currentOrganization?.name ?? ''}
                    </span>
                    <LogoChevron name="chevronDown" $isOpen={isOpen} />
                </LogoContainer>
            )}
        >
            <DropdownContent>
                <SelectBasicItem
                    onClick={() => {
                        navigate(route('profile'))
                    }}
                >
                    <SelectBasicItemIconWrap>
                        <Icon name="userCircle" />
                    </SelectBasicItemIconWrap>
                    Profile
                </SelectBasicItem>

                {currentOrganization && userIsOwnerInCurrentOrg && (
                    <SelectBasicItem
                        onClick={() => {
                            navigate(
                                route('organization', currentOrganization.id)
                            )
                        }}
                    >
                        <SelectBasicItemIconWrap>
                            <Icon name="settings" />
                        </SelectBasicItemIconWrap>
                        Organization Settings
                    </SelectBasicItem>
                )}

                {userOrgs
                    .filter((org) => org.id !== currentOrganization?.id)
                    .map((org) => (
                        <SelectBasicItem
                            key={org.id}
                            onClick={async () => {
                                // Org switching is implemented via team switching,
                                // as every team is in some org
                                if (org.teams[0]) {
                                    await switchTeam(org.teams[0].id)
                                    navigate(route('home'))
                                }
                            }}
                        >
                            <SelectBasicItemIconWrap>
                                <Icon name="building" />
                            </SelectBasicItemIconWrap>
                            Switch to {org.name}
                        </SelectBasicItem>
                    ))}

                <StyledSeparator />
                <SelectBasicItem
                    onClick={() => {
                        logout(true)
                    }}
                >
                    <SelectBasicItemIconWrap>
                        <Icon name="signOut" />
                    </SelectBasicItemIconWrap>
                    Log out
                </SelectBasicItem>
            </DropdownContent>
        </Dropdown>
    )
}

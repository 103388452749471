import React, { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import { useTeamSelection } from 'utils/useTeamSelection'
import { Layout } from '../components/Layout'
import { Button } from '../components/Button'
import { Icon } from '../components/Icon'
import { themeVariables } from '../themes/themeVariables'
import { BaseButton } from '../components/BaseButton'
import { Tooltip } from '../components/Tooltip'
import { Table } from '../components/Table'
import { UserAvatar } from '../components/UserAvatar'
import { UserAvatarPlaceholder } from '../components/UserAvatarPlaceholder'
import { Dropdown, DropdownContent } from '../components/Dropdown'
import { SelectBasicItem } from '../components/Form/Select/SelectItemRenderer'
import {
    useDeleteTeamInvite,
    useRemoveUserFromTeam,
    useUpdateTeam,
    useUpdateUserRoleInTeam,
} from '../utils/mutations'
import { DotdotdotButton } from '../components/DotdotdotButton'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { confirmModal } from '../components/modals/ConfirmationModal'
import {
    useCurrentUserQuery,
    useTeamInvitesQuery,
    useTeamQuery,
} from '../utils/queries'
import { useAuth } from '../providers/AuthProvider'
import { useInviteTeamMemberModal } from '../components/modals/InviteTeamMemberModal'
import { Badge } from '../components/Badge'
import { useNameInputModal } from '../components/modals/NameInputModal'
import { showToast, ToastType } from '../components/Toast/Toast'
import { TableDataLoading } from '../components/TableDataLoading'
import { LoadMoreButton } from '../components/LoadMoreButton'
import { TeamMemberOrInvite } from '../utils/team'
import { useRequiredParam } from '../utils/misc'

const Container = styled.div`
    padding: 0 40px;
`

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
`

const IdContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    background-color: ${themeVariables.colors.backgroundContainer};
    color: ${themeVariables.colors.secondary};
    width: fit-content;
    padding: 4px;
    font-size: ${themeVariables.typography.fontSizes.caption};
`

const CopyIcon = styled(Icon)`
    width: 14px;
    height: 14px;
    svg {
        width: 14px;
        height: 14px;
    }
    color: ${themeVariables.colors.secondary};
`

const CopyButton = styled(BaseButton)`
    padding: 4px;
`
const MemberContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`

const RotatingIcon = styled(Icon)`
    transition: transform 0.2s ease-in-out;
    &.isOpen {
        transform: rotate(180deg);
    }
`

export const TeamPage = () => {
    const { session } = useAuth()
    const teamId = useRequiredParam('id')
    const { currentOrganization, switchTeam } = useTeamSelection()
    const { data: team, isFetching: isLoadingTeam } = useTeamQuery(teamId)
    const { data: user } = useCurrentUserQuery()

    const {
        data: teamInvites,
        isLoading: isLoadingTeamInvites,
        isFetchingNextPage: isLoadingMoreTeamInvites,
        hasNextPage: hasMoreTeamInvites,
        fetchNextPage: fetchMoreTeamInvites,
    } = useTeamInvitesQuery({ teamId })

    const currentUserIsOwner = useMemo(() => {
        if (!team || !user) {
            return false
        }

        const userIsOwnerInTeam =
            team.userRoles.find(
                (userRole) =>
                    userRole.userId === session?.userId &&
                    userRole.role === 'Owner'
            ) !== undefined

        const userIsOwnerInOrg = user.organizationRoles.find(
            (orgRole) =>
                orgRole.role === 'Owner' &&
                orgRole.organizationId === team.organizationId
        )

        return userIsOwnerInTeam || userIsOwnerInOrg
    }, [team, user])

    const invitesAndMembers = useMemo<TeamMemberOrInvite[]>(() => {
        if (!teamInvites || !team) {
            return []
        }

        const mappedInvites = teamInvites
            .map((invite) => ({
                inviteId: invite.id,
                type: 'invite' as const,
                userId: invite.userId || undefined,
                userName: invite.user?.name,
                userEmail: invite.email,
                avatarBlobId: invite.user?.avatarBlobId || undefined,
                role: invite.role,
            }))
            .sort((a, b) => {
                if (a.role !== b.role) {
                    return a.role > b.role ? -1 : 1
                }
                const nameA = a.userName ?? a.userEmail
                const nameB = b.userName ?? b.userEmail
                return nameA.localeCompare(nameB)
            })

        const mappedUserRoles = team.userRoles.map((userRole) => ({
            type: 'teamMember' as const,
            userId: userRole.userId,
            userName: userRole.user.name,
            userEmail: userRole.user.email,
            avatarBlobId: userRole.user.avatarBlobId || undefined,
            role: userRole.role,
        }))

        return [...mappedInvites, ...mappedUserRoles]
    }, [teamInvites, team])

    const updateUserRole = useUpdateUserRoleInTeam()
    const [updatedRole, setUpdatedRole] = useState<
        'Owner' | 'Member' | undefined
    >(undefined)
    const [updatedUser, setUpdatedUser] = useState<string | undefined>(
        undefined
    )
    const deleteTeamInvite = useDeleteTeamInvite()
    const removeUserFromTeam = useRemoveUserFromTeam()
    const updateTeam = useUpdateTeam()
    const nameInputModal = useNameInputModal()
    const triggerInviteTeamMemberModal = useInviteTeamMemberModal()

    // If the given teamId doesn't match the currentOrganization, switch the active team to that org
    useEffect(() => {
        if (
            user &&
            currentOrganization &&
            team &&
            currentOrganization.id !== team.organizationId
        ) {
            const userHasRoleInTargetTeam = user.teamRoles.find(
                (teamRole) => teamRole.teamId === teamId
            )
            if (userHasRoleInTargetTeam) {
                console.log(
                    `Automatic team switch: Selected org doesn't match requested team's org - switching selected team to requested team`
                )
                switchTeam(teamId)
            } else {
                const usersTeamInTargetOrg = user.teamRoles.find(
                    (teamRole) =>
                        teamRole.team.organizationId === team.organizationId
                )?.teamId
                if (usersTeamInTargetOrg) {
                    console.log(
                        `Automatic team switch: Selected org doesn't match requested team's org, and user is not part of selected team - switching to some team in requested team's org`
                    )
                    switchTeam(usersTeamInTargetOrg)
                }
            }
        }
    }, [user, currentOrganization, team])

    return (
        <Layout>
            <Container>
                <HeaderContainer>
                    <h1>{team?.name ?? '\u200C'}</h1>
                    <div className={cx('d-flex', 'g-10')}>
                        <Tooltip
                            content={
                                currentUserIsOwner
                                    ? undefined
                                    : 'Your role in this team does not allow you to edit it'
                            }
                        >
                            <Button
                                $variant="secondary"
                                className={cx(
                                    'd-flex',
                                    'g-5',
                                    'align-items-center'
                                )}
                                disabled={
                                    !currentUserIsOwner ||
                                    !team ||
                                    !!team.personalTeamOf
                                }
                                onClick={() => {
                                    if (team) {
                                        nameInputModal({
                                            onConfirm: async (
                                                newName: string
                                            ) => {
                                                await updateTeam.mutateAsync({
                                                    id: team.id,
                                                    name: newName,
                                                })
                                            },
                                            modalTitle: 'Edit team name',
                                            buttonCtaText: 'Save',
                                            buttonCtaSavingText: 'Saving',
                                            currentName: team.name,
                                        })
                                    }
                                }}
                            >
                                <Icon name="edit" />
                                Rename
                            </Button>
                        </Tooltip>
                        <Tooltip
                            content={
                                currentUserIsOwner
                                    ? undefined
                                    : 'Your role in this team does not allow you to invite members'
                            }
                        >
                            <Button
                                className={cx(
                                    'd-flex',
                                    'g-5',
                                    'align-items-center'
                                )}
                                disabled={
                                    !currentUserIsOwner ||
                                    !team ||
                                    !!team.personalTeamOf
                                }
                                onClick={() => {
                                    if (team) {
                                        triggerInviteTeamMemberModal(team)
                                    }
                                }}
                            >
                                <Icon name="plus" />
                                Invite member
                            </Button>
                        </Tooltip>
                    </div>
                </HeaderContainer>
                <IdContainer>
                    ID: {team?.id}
                    <Tooltip content="Copy to clipboard">
                        <CopyButton
                            onClick={() => {
                                navigator.clipboard.writeText(teamId)
                                showToast({
                                    title: 'Team ID copied to clipboard',
                                    type: ToastType.Success,
                                })
                            }}
                        >
                            <CopyIcon name="copy" />
                        </CopyButton>
                    </Tooltip>
                </IdContainer>
            </Container>
            <div className={cx('m-t-40', 'm-b-40')}>
                <HorizontalLoadingIndicator
                    loading={
                        updateUserRole.isPending ||
                        removeUserFromTeam.isPending ||
                        isLoadingTeam ||
                        deleteTeamInvite.isPending ||
                        isLoadingTeamInvites ||
                        isLoadingMoreTeamInvites
                    }
                />
                <Table
                    columns={[
                        {
                            title: 'Name',
                            key: 'name',
                            render: (row) => (
                                <MemberContainer>
                                    <div>
                                        {row.avatarBlobId ? (
                                            <UserAvatar
                                                avatarBlobId={row.avatarBlobId}
                                                size={32}
                                            />
                                        ) : (
                                            <UserAvatarPlaceholder
                                                userName={
                                                    row.userName ||
                                                    row.userEmail
                                                }
                                                size={32}
                                            />
                                        )}
                                    </div>
                                    <div>
                                        <p
                                            className={cx(
                                                'm-b-0',
                                                'emphasized'
                                            )}
                                        >
                                            {row.userName || row.userEmail}
                                        </p>
                                        {typeof row.userName !==
                                            'undefined' && (
                                            <p
                                                className={cx(
                                                    'm-b-0',
                                                    'secondary'
                                                )}
                                            >
                                                {row.userEmail}
                                            </p>
                                        )}
                                    </div>
                                    {row.type === 'invite' && (
                                        <Badge $variant="default">
                                            Pending
                                        </Badge>
                                    )}
                                </MemberContainer>
                            ),
                        },
                        {
                            title: 'Role in Team',
                            key: 'role',
                            render: (row) =>
                                currentUserIsOwner &&
                                row.type === 'teamMember' &&
                                !team?.personalTeamOf ? (
                                    <Dropdown
                                        trigger={({ onClick, isOpen }) => (
                                            <BaseButton
                                                className={cx(
                                                    'd-flex',
                                                    'g-5',
                                                    'align-items-center',
                                                    'p-l-0',
                                                    'p-r-0'
                                                )}
                                                onClick={onClick}
                                            >
                                                {row.role}
                                                <RotatingIcon
                                                    name="chevronDown"
                                                    className={cx({
                                                        isOpen,
                                                    })}
                                                />
                                            </BaseButton>
                                        )}
                                    >
                                        {(dismiss) => (
                                            <DropdownContent>
                                                <SelectBasicItem
                                                    type="button"
                                                    onClick={() => {
                                                        if (
                                                            row.role !== 'Owner'
                                                        ) {
                                                            setUpdatedRole(
                                                                'Owner'
                                                            )
                                                            setUpdatedUser(
                                                                row.userId
                                                            )
                                                            updateUserRole.mutate(
                                                                {
                                                                    teamId,
                                                                    userId: row.userId!,
                                                                    role: 'Owner',
                                                                },
                                                                {
                                                                    onSuccess:
                                                                        () => {
                                                                            dismiss()
                                                                            setUpdatedRole(
                                                                                undefined
                                                                            )
                                                                            setUpdatedUser(
                                                                                undefined
                                                                            )
                                                                        },
                                                                    onError:
                                                                        () => {
                                                                            setUpdatedRole(
                                                                                undefined
                                                                            )
                                                                            setUpdatedUser(
                                                                                undefined
                                                                            )
                                                                        },
                                                                }
                                                            )
                                                        }
                                                    }}
                                                    disabled={
                                                        updatedUser ===
                                                            row.userId &&
                                                        typeof updatedRole !==
                                                            'undefined'
                                                    }
                                                >
                                                    <div
                                                        className={cx(
                                                            'w-100',
                                                            'd-flex',
                                                            'g-5',
                                                            'justify-content-between',
                                                            'align-items-center'
                                                        )}
                                                    >
                                                        {updatedUser ===
                                                            row.userId &&
                                                        updatedRole ===
                                                            'Owner' ? (
                                                            <>
                                                                Saving...
                                                                <Icon name="spinnerBlue" />
                                                            </>
                                                        ) : (
                                                            <>
                                                                Owner
                                                                {row.role ===
                                                                    'Owner' && (
                                                                    <Icon name="check" />
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </SelectBasicItem>
                                                <SelectBasicItem
                                                    type="button"
                                                    onClick={() => {
                                                        if (
                                                            row.role !==
                                                            'Member'
                                                        ) {
                                                            setUpdatedRole(
                                                                'Member'
                                                            )
                                                            setUpdatedUser(
                                                                row.userId
                                                            )
                                                            updateUserRole.mutate(
                                                                {
                                                                    teamId,
                                                                    userId: row.userId!,
                                                                    role: 'Member',
                                                                },
                                                                {
                                                                    onSuccess:
                                                                        () => {
                                                                            dismiss()
                                                                            setUpdatedRole(
                                                                                undefined
                                                                            )
                                                                            setUpdatedUser(
                                                                                undefined
                                                                            )
                                                                        },
                                                                    onError:
                                                                        () => {
                                                                            setUpdatedRole(
                                                                                undefined
                                                                            )
                                                                            setUpdatedUser(
                                                                                undefined
                                                                            )
                                                                        },
                                                                }
                                                            )
                                                        }
                                                    }}
                                                    disabled={
                                                        updatedUser ===
                                                            row.userId &&
                                                        typeof updatedRole !==
                                                            'undefined'
                                                    }
                                                >
                                                    <div
                                                        className={cx(
                                                            'w-100',
                                                            'd-flex',
                                                            'g-5',
                                                            'justify-content-between',
                                                            'align-items-center'
                                                        )}
                                                    >
                                                        {updatedUser ===
                                                            row.userId &&
                                                        updatedRole ===
                                                            'Member' ? (
                                                            <>
                                                                Saving...
                                                                <Icon name="spinnerBlue" />
                                                            </>
                                                        ) : (
                                                            <>
                                                                Member
                                                                {row.role ===
                                                                    'Member' && (
                                                                    <Icon name="check" />
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </SelectBasicItem>
                                            </DropdownContent>
                                        )}
                                    </Dropdown>
                                ) : (
                                    row.role
                                ),
                        },
                        {
                            key: 'actions',
                            render: (row) => (
                                <div
                                    className={cx(
                                        'd-flex',
                                        'w-100',
                                        'justify-content-end'
                                    )}
                                >
                                    <Dropdown
                                        trigger={({ onClick }) => (
                                            <DotdotdotButton
                                                onClick={onClick}
                                                flip
                                            />
                                        )}
                                    >
                                        {(dismiss) => (
                                            <DropdownContent>
                                                {row.type === 'teamMember' && (
                                                    <SelectBasicItem
                                                        type="button"
                                                        className={cx(
                                                            'd-flex',
                                                            'g-10',
                                                            'align-items-center'
                                                        )}
                                                        disabled={
                                                            !currentUserIsOwner ||
                                                            !!team?.personalTeamOf
                                                        }
                                                        onClick={async () => {
                                                            dismiss()
                                                            try {
                                                                await confirmModal.pop(
                                                                    {
                                                                        title: 'Remove user from team',
                                                                        content: `Are you sure you want to remove ${row.userName} from the team "${team?.name}"?`,
                                                                        onResolve:
                                                                            () => {
                                                                                removeUserFromTeam.mutate(
                                                                                    {
                                                                                        teamId,
                                                                                        userId: row.userId!,
                                                                                    }
                                                                                )
                                                                            },
                                                                        confirmButtonText:
                                                                            'Remove',
                                                                        cancelButtonText:
                                                                            'Cancel',
                                                                    }
                                                                )
                                                            } catch (_) {
                                                                // do nothing
                                                            }
                                                        }}
                                                    >
                                                        <Icon name="close2" />
                                                        Remove from team
                                                    </SelectBasicItem>
                                                )}
                                                {row.type === 'invite' && (
                                                    <>
                                                        <SelectBasicItem
                                                            type="button"
                                                            onClick={async () => {
                                                                dismiss()
                                                                try {
                                                                    await confirmModal.pop(
                                                                        {
                                                                            title: 'Cancel invitation',
                                                                            content: `Are you sure you want to remove the invite for ${row.userEmail}?`,
                                                                            confirmButtonText:
                                                                                'Remove',
                                                                            cancelButtonText:
                                                                                'Cancel',
                                                                            onResolve:
                                                                                () => {
                                                                                    deleteTeamInvite.mutate(
                                                                                        {
                                                                                            teamId,
                                                                                            inviteId:
                                                                                                row.inviteId!,
                                                                                        }
                                                                                    )
                                                                                },
                                                                        }
                                                                    )
                                                                } catch (_) {
                                                                    // do nothing
                                                                }
                                                            }}
                                                        >
                                                            <Icon name="close2" />
                                                            Cancel invitation
                                                        </SelectBasicItem>
                                                    </>
                                                )}
                                            </DropdownContent>
                                        )}
                                    </Dropdown>
                                </div>
                            ),
                        },
                    ]}
                    data={invitesAndMembers || []}
                />
                {(isLoadingTeam || isLoadingTeam) && <TableDataLoading />}
                {hasMoreTeamInvites && (
                    <LoadMoreButton
                        onClick={fetchMoreTeamInvites}
                        isLoading={isLoadingMoreTeamInvites}
                    />
                )}
            </div>
        </Layout>
    )
}

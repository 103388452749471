import { useEffect } from 'react'
import { useCurrentUserQuery } from 'utils/queries'
import { getStoredTeam, storeCurrentTeam, clearStoredTeam } from 'utils/team'
import { apiClient } from 'utils/clients'
import { getSessionToken } from 'utils/session'

export function ApiClientTeamSetter() {
    const sessionToken = getSessionToken()
    const { data: user } = useCurrentUserQuery({ enabled: !!sessionToken })

    useEffect(() => {
        if (!user?.id || !user?.teamRoles) return

        const storedTeam = getStoredTeam(user.id)
        const team =
            user.teamRoles.find(
                (teamRole) => teamRole.teamId === storedTeam?.id
            ) || user.teamRoles[0]

        if (team) {
            apiClient.setSelectedTeam(team.teamId)
            storeCurrentTeam(user.id, team.team)
        } else {
            apiClient.setSelectedTeam(undefined)
            clearStoredTeam(user.id)
        }
    }, [user?.id, user?.teamRoles])

    return null
}

import React, { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import { route } from 'utils/routes'
import { useTeamSelection } from 'utils/useTeamSelection'
import { Layout } from '../components/Layout'
import { Button } from '../components/Button'
import { Icon } from '../components/Icon'
import { themeVariables } from '../themes/themeVariables'
import { BaseButton } from '../components/BaseButton'
import { Tooltip } from '../components/Tooltip'
import { Table } from '../components/Table'
import { UserAvatar } from '../components/UserAvatar'
import { UserAvatarPlaceholder } from '../components/UserAvatarPlaceholder'
import { Dropdown, DropdownContent } from '../components/Dropdown'
import { SelectBasicItem } from '../components/Form/Select/SelectItemRenderer'
import {
    useCreateTeam,
    useDeleteTeam,
    useRemoveUserFromOrganization,
    useUpdateOrganization,
    useUpdateUserRoleInOrganization,
} from '../utils/mutations'
import { DotdotdotButton } from '../components/DotdotdotButton'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { confirmModal } from '../components/modals/ConfirmationModal'
import {
    useCurrentUserQuery,
    useOrganizationBalance,
    useOrganizationQuery,
} from '../utils/queries'
import { useAuth } from '../providers/AuthProvider'
import { useNameInputModal } from '../components/modals/NameInputModal'
import { TableDataLoading } from '../components/TableDataLoading'
import { useRequiredParam } from '../utils/misc'

const Container = styled.div`
    padding: 0 40px;
`

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
`

const CreditContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    background-color: ${themeVariables.colors.backgroundContainer};
    color: ${themeVariables.colors.secondary};
    width: fit-content;
    padding: 4px;
    font-size: ${themeVariables.typography.fontSizes.caption};
`
const MemberContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`
const RotatingIcon = styled(Icon)`
    transition: transform 0.2s ease-in-out;
    &.isOpen {
        transform: rotate(180deg);
    }
`

export const OrganizationPage = () => {
    const navigate = useNavigate()
    const { session } = useAuth()
    const orgId = useRequiredParam('id')
    const { data: user } = useCurrentUserQuery()
    const { currentOrganization, switchTeam } = useTeamSelection()
    const { data: org, isFetching: isLoadingOrg } = useOrganizationQuery(orgId)
    const { data: balance, isFetching: isFetchingBalance } =
        useOrganizationBalance(orgId)

    // If the given orgId doesn't match the currentOrganization, switch to the orgId for better UX
    useEffect(() => {
        if (
            user &&
            currentOrganization &&
            org &&
            currentOrganization.id !== org.id
        ) {
            const usersTeamInTargetOrg = user.teamRoles.find(
                (teamRole) => teamRole.team.organizationId === org.id
            )?.teamId
            if (usersTeamInTargetOrg) {
                console.log(
                    `Automatic team switch: Selected org doesn't match requested org - switching selected org to requested org`
                )
                switchTeam(usersTeamInTargetOrg)
            }
        }
    }, [user, currentOrganization, org])

    const currentUserIsOwner = useMemo(() => {
        if (!org || !session?.userId) {
            return false
        }
        return (
            org.userRoles.filter(
                (userRole) =>
                    userRole.userId === session?.userId &&
                    userRole.role === 'Owner'
            ).length > 0
        )
    }, [org, session?.userId])

    const deleteTeam = useDeleteTeam(orgId)
    const updateUserRole = useUpdateUserRoleInOrganization()
    const [updatedRole, setUpdatedRole] = useState<
        'Owner' | 'Member' | undefined
    >(undefined)
    const [updatedUser, setUpdatedUser] = useState<string | undefined>(
        undefined
    )
    const removeUserFromOrganization = useRemoveUserFromOrganization()
    const updateOrganization = useUpdateOrganization()
    const nameInputModal = useNameInputModal()
    const createTeam = useCreateTeam()

    const userTeams = useMemo(() => {
        if (!org) {
            return {}
        }

        const teamsByUserId: Record<string, string[]> = {}

        org.userRoles.forEach((userInOrg) => {
            teamsByUserId[userInOrg.userId] = org.teams
                .filter((team) =>
                    team.userRoles.some(
                        (roleInTeam) => roleInTeam.userId === userInOrg.userId
                    )
                )
                .map((team) => team.name)
        })

        return teamsByUserId
    }, [org])

    return (
        <Layout>
            <Container>
                <HeaderContainer>
                    <h1>{org?.name ?? '\u200C'}</h1>
                    <div className={cx('d-flex', 'g-10')}>
                        <Tooltip
                            content={
                                currentUserIsOwner
                                    ? undefined
                                    : 'Your role in this organization does not allow you to edit it'
                            }
                        >
                            <Button
                                $variant="secondary"
                                className={cx(
                                    'd-flex',
                                    'g-5',
                                    'align-items-center'
                                )}
                                disabled={!currentUserIsOwner || !org}
                                onClick={() => {
                                    if (org) {
                                        nameInputModal({
                                            onConfirm: async (
                                                newName: string
                                            ) => {
                                                await updateOrganization.mutateAsync(
                                                    {
                                                        id: org.id,
                                                        name: newName,
                                                    }
                                                )
                                            },
                                            modalTitle:
                                                'Edit organization name',
                                            buttonCtaText: 'Save',
                                            buttonCtaSavingText: 'Saving',
                                            currentName: org.name,
                                        })
                                    }
                                }}
                            >
                                <Icon name="edit" />
                                Rename Organization
                            </Button>
                        </Tooltip>
                    </div>
                </HeaderContainer>
                <CreditContainer>
                    Credits:{' '}
                    {isFetchingBalance
                        ? '...'
                        : new Intl.NumberFormat().format(balance!.balance)}
                </CreditContainer>
            </Container>

            <Container className="m-t-40">
                <HeaderContainer>
                    <h2>Teams</h2>
                    <div className={cx('d-flex', 'g-10')}>
                        <Button
                            $variant="secondary"
                            className={cx(
                                'd-flex',
                                'g-5',
                                'align-items-center'
                            )}
                            disabled={!currentUserIsOwner || !org}
                            onClick={() => {
                                nameInputModal({
                                    onConfirm: async (name) => {
                                        const newTeam =
                                            await createTeam.mutateAsync({
                                                name,
                                            })
                                        navigate(route('team', newTeam.id))
                                    },
                                    modalTitle: 'Create new team',
                                    buttonCtaText: 'Create team',
                                    buttonCtaSavingText: 'Creating...',
                                    additionalText:
                                        'After creating a team, you can invite others to join',
                                })
                            }}
                        >
                            <Icon name="plus" />
                            Create Team
                        </Button>
                    </div>
                </HeaderContainer>
            </Container>
            <div>
                <HorizontalLoadingIndicator loading={isLoadingOrg} />
                <Table
                    onRowClick={(row) => {
                        navigate(route('team', row.id))
                    }}
                    noClickColumnKeys={['actions']}
                    columns={[
                        {
                            title: 'Name',
                            key: 'team',
                            render: (team) => team.name,
                        },
                        {
                            title: 'Members',
                            key: 'members',
                            render: (team) => team.userRoles.length,
                        },
                        {
                            key: 'actions',
                            render: (team) => (
                                <div
                                    className={cx(
                                        'd-flex',
                                        'w-100',
                                        'justify-content-end'
                                    )}
                                >
                                    <Dropdown
                                        trigger={({ onClick }) => (
                                            <DotdotdotButton
                                                onClick={onClick}
                                                flip
                                            />
                                        )}
                                    >
                                        {(dismiss) => (
                                            <DropdownContent>
                                                <SelectBasicItem
                                                    type="button"
                                                    className={cx(
                                                        'd-flex',
                                                        'g-10',
                                                        'align-items-center'
                                                    )}
                                                    disabled={
                                                        !currentUserIsOwner
                                                    }
                                                    onClick={async () => {
                                                        dismiss()
                                                        try {
                                                            await confirmModal.pop(
                                                                {
                                                                    title: 'Delete team',
                                                                    content: `Are you sure you want to delete the team ${team.name}? Note that this will fail if the team contains any resources!`,
                                                                    onResolve:
                                                                        () => {
                                                                            deleteTeam.mutate(
                                                                                {
                                                                                    teamId: team.id,
                                                                                }
                                                                            )
                                                                        },
                                                                    confirmButtonText:
                                                                        'Delete',
                                                                    cancelButtonText:
                                                                        'Cancel',
                                                                }
                                                            )
                                                        } catch (_) {
                                                            // do nothing
                                                        }
                                                    }}
                                                >
                                                    <Icon name="close2" />
                                                    Delete
                                                </SelectBasicItem>
                                            </DropdownContent>
                                        )}
                                    </Dropdown>
                                </div>
                            ),
                        },
                    ]}
                    data={org?.teams || []}
                />
                {(isLoadingOrg || isLoadingOrg) && <TableDataLoading />}
            </div>

            <Container className="m-t-40">
                <h2>Members</h2>
            </Container>
            <div>
                <HorizontalLoadingIndicator
                    loading={
                        updateUserRole.isPending ||
                        removeUserFromOrganization.isPending ||
                        isLoadingOrg
                    }
                />
                <Table
                    columns={[
                        {
                            title: 'Name',
                            key: 'name',
                            render: (row) => (
                                <MemberContainer>
                                    <div>
                                        {row.user.avatarBlobId ? (
                                            <UserAvatar
                                                avatarBlobId={
                                                    row.user.avatarBlobId
                                                }
                                                size={32}
                                            />
                                        ) : (
                                            <UserAvatarPlaceholder
                                                userName={row.user.name}
                                                size={32}
                                            />
                                        )}
                                    </div>
                                    <div>
                                        <p
                                            className={cx(
                                                'm-b-0',
                                                'emphasized'
                                            )}
                                        >
                                            {row.user.name}
                                        </p>
                                        <p className={cx('m-b-0', 'secondary')}>
                                            {row.user.email}
                                        </p>
                                    </div>
                                </MemberContainer>
                            ),
                        },
                        {
                            title: 'Role in Organization',
                            key: 'role',
                            render: (row) =>
                                currentUserIsOwner ? (
                                    <Dropdown
                                        trigger={({ onClick, isOpen }) => (
                                            <BaseButton
                                                className={cx(
                                                    'd-flex',
                                                    'g-5',
                                                    'align-items-center',
                                                    'p-l-0',
                                                    'p-r-0'
                                                )}
                                                onClick={onClick}
                                            >
                                                {row.role}
                                                <RotatingIcon
                                                    name="chevronDown"
                                                    className={cx({
                                                        isOpen,
                                                    })}
                                                />
                                            </BaseButton>
                                        )}
                                    >
                                        {(dismiss) => (
                                            <DropdownContent>
                                                <SelectBasicItem
                                                    type="button"
                                                    onClick={() => {
                                                        if (
                                                            row.role !== 'Owner'
                                                        ) {
                                                            setUpdatedRole(
                                                                'Owner'
                                                            )
                                                            setUpdatedUser(
                                                                row.userId
                                                            )
                                                            updateUserRole.mutate(
                                                                {
                                                                    orgId,
                                                                    userId: row.userId!,
                                                                    role: 'Owner',
                                                                },
                                                                {
                                                                    onSuccess:
                                                                        () => {
                                                                            dismiss()
                                                                            setUpdatedRole(
                                                                                undefined
                                                                            )
                                                                            setUpdatedUser(
                                                                                undefined
                                                                            )
                                                                        },
                                                                    onError:
                                                                        () => {
                                                                            setUpdatedRole(
                                                                                undefined
                                                                            )
                                                                            setUpdatedUser(
                                                                                undefined
                                                                            )
                                                                        },
                                                                }
                                                            )
                                                        }
                                                    }}
                                                    disabled={
                                                        updatedUser ===
                                                            row.userId &&
                                                        typeof updatedRole !==
                                                            'undefined'
                                                    }
                                                >
                                                    <div
                                                        className={cx(
                                                            'w-100',
                                                            'd-flex',
                                                            'g-5',
                                                            'justify-content-between',
                                                            'align-items-center'
                                                        )}
                                                    >
                                                        {updatedUser ===
                                                            row.userId &&
                                                        updatedRole ===
                                                            'Owner' ? (
                                                            <>
                                                                Saving...
                                                                <Icon name="spinnerBlue" />
                                                            </>
                                                        ) : (
                                                            <>
                                                                Owner
                                                                {row.role ===
                                                                    'Owner' && (
                                                                    <Icon name="check" />
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </SelectBasicItem>
                                                <SelectBasicItem
                                                    type="button"
                                                    onClick={() => {
                                                        if (
                                                            row.role !==
                                                            'Member'
                                                        ) {
                                                            setUpdatedRole(
                                                                'Member'
                                                            )
                                                            setUpdatedUser(
                                                                row.userId
                                                            )
                                                            updateUserRole.mutate(
                                                                {
                                                                    orgId,
                                                                    userId: row.userId!,
                                                                    role: 'Member',
                                                                },
                                                                {
                                                                    onSuccess:
                                                                        () => {
                                                                            dismiss()
                                                                            setUpdatedRole(
                                                                                undefined
                                                                            )
                                                                            setUpdatedUser(
                                                                                undefined
                                                                            )
                                                                        },
                                                                    onError:
                                                                        () => {
                                                                            setUpdatedRole(
                                                                                undefined
                                                                            )
                                                                            setUpdatedUser(
                                                                                undefined
                                                                            )
                                                                        },
                                                                }
                                                            )
                                                        }
                                                    }}
                                                    disabled={
                                                        updatedUser ===
                                                            row.userId &&
                                                        typeof updatedRole !==
                                                            'undefined'
                                                    }
                                                >
                                                    <div
                                                        className={cx(
                                                            'w-100',
                                                            'd-flex',
                                                            'g-5',
                                                            'justify-content-between',
                                                            'align-items-center'
                                                        )}
                                                    >
                                                        {updatedUser ===
                                                            row.userId &&
                                                        updatedRole ===
                                                            'Member' ? (
                                                            <>
                                                                Saving...
                                                                <Icon name="spinnerBlue" />
                                                            </>
                                                        ) : (
                                                            <>
                                                                Member
                                                                {row.role ===
                                                                    'Member' && (
                                                                    <Icon name="check" />
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </SelectBasicItem>
                                            </DropdownContent>
                                        )}
                                    </Dropdown>
                                ) : (
                                    row.role
                                ),
                        },
                        {
                            title: 'Teams',
                            key: 'teams',
                            render: (row) => {
                                const teamNames = userTeams[row.userId] ?? []
                                const teamCount = teamNames.length

                                if (teamCount > 2) {
                                    return (
                                        <Tooltip content={teamNames.join(', ')}>
                                            {`${teamNames.slice(0, 2).join(', ')}... (${teamCount - 2} more)`}
                                        </Tooltip>
                                    )
                                }

                                if (teamCount === 0) {
                                    return (
                                        <Tooltip
                                            content={`The user doesn't belong to any teams in this organization.`}
                                        >
                                            (None)
                                        </Tooltip>
                                    )
                                }

                                return teamNames.join(', ')
                            },
                        },
                        {
                            key: 'actions',
                            render: (row) => (
                                <div
                                    className={cx(
                                        'd-flex',
                                        'w-100',
                                        'justify-content-end'
                                    )}
                                >
                                    <Dropdown
                                        trigger={({ onClick }) => (
                                            <DotdotdotButton
                                                onClick={onClick}
                                                flip
                                            />
                                        )}
                                    >
                                        {(dismiss) => (
                                            <DropdownContent>
                                                <SelectBasicItem
                                                    type="button"
                                                    className={cx(
                                                        'd-flex',
                                                        'g-10',
                                                        'align-items-center'
                                                    )}
                                                    disabled={
                                                        !currentUserIsOwner
                                                    }
                                                    onClick={async () => {
                                                        dismiss()
                                                        try {
                                                            await confirmModal.pop(
                                                                {
                                                                    title: 'Remove user from organization',
                                                                    content: `Are you sure you want to remove ${row.user.name} from the organization "${org?.name}" and all its teams?`,
                                                                    onResolve:
                                                                        () => {
                                                                            removeUserFromOrganization.mutate(
                                                                                {
                                                                                    orgId,
                                                                                    userId: row.userId!,
                                                                                }
                                                                            )
                                                                        },
                                                                    confirmButtonText:
                                                                        'Remove',
                                                                    cancelButtonText:
                                                                        'Cancel',
                                                                }
                                                            )
                                                        } catch (_) {
                                                            // do nothing
                                                        }
                                                    }}
                                                >
                                                    <Icon name="close2" />
                                                    Remove
                                                </SelectBasicItem>
                                            </DropdownContent>
                                        )}
                                    </Dropdown>
                                </div>
                            ),
                        },
                    ]}
                    data={org?.userRoles || []}
                />
                {(isLoadingOrg || isLoadingOrg) && <TableDataLoading />}
            </div>
        </Layout>
    )
}

import React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { Separator } from '../components/Separator'
import { themeVariables } from '../themes/themeVariables'
import { Icon } from '../components/Icon'
import { Button } from '../components/Button'
import { route } from '../utils/routes'
import { useAssessmentsQuery } from '../utils/queries'
import { useAuth } from '../providers/AuthProvider'
import { AssessmentProgress } from '../components/AssessmentProgress'
import { useTeamSelection } from '../utils/useTeamSelection'

const Container = styled.div`
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 19px;
    margin-top: 50px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`

export const CTAIconContainer = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${themeVariables.palettes.brand100};
    display: flex;
    align-items: center;
    justify-content: center;
`

const CTAHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`

export const CTAIcon = styled(Icon)`
    color: ${themeVariables.colors.brand};
    width: 15px;
    height: 15px;
    svg {
        width: 15px;
        height: 15px;
    }
`

const GrayBox = styled.div`
    background-color: ${themeVariables.colors.backgroundContainer};
    padding: 32px;
    display: flex;
    flex: 1;
    gap: 12px;
`
const BoxTitle = styled.div`
    font-size: ${themeVariables.typography.fontSizes.large};
    margin-top: 5px;
    margin-bottom: 0;
`

const WhiteBox = styled.div`
    border: 1px solid ${themeVariables.colors.border};
    padding: 32px;
    display: flex;
    flex: 1;
    gap: 12px;
`

const AssessmentName = styled.p`
    font-size: ${themeVariables.typography.fontSizes.large};
    margin-top: 3px;
    margin-bottom: 0;
`

const BoxLeftColumn = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
`

const BoxRightColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const ProjectName = styled.p`
    margin-top: 15px;
    margin-bottom: 0;
`

const ProjectCustomId = styled.p`
    margin-bottom: 40px;
    color: ${themeVariables.colors.secondary};
`

const ChevronIcon = styled(Icon)`
    color: ${themeVariables.colors.secondary};
    width: 15px;
    height: 15px;
    svg {
        width: 15px;
        height: 15px;
    }
`

export const HomePage = () => {
    const auth = useAuth()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const assessmentsQuery = useAssessmentsQuery({
        assignedToId: auth.session?.userId,
        pageSize: 2,
    })
    const { currentTeamId } = useTeamSelection()
    return (
        <Layout>
            <ListTopBar />
            <HorizontalLoadingIndicator loading={assessmentsQuery.isLoading} />
            <Container>
                <h1>Welcome to the {t('appName')}</h1>
                <Separator className={cx('m-t-25', 'm-b-50')} />
                <h3 className="m-b-30">⚡ Quickstart</h3>
                <Row>
                    <GrayBox>
                        <BoxLeftColumn>
                            <CTAIconContainer>
                                <CTAIcon name="grid" />
                            </CTAIconContainer>
                        </BoxLeftColumn>
                        <BoxRightColumn>
                            <CTAHeader>
                                <BoxTitle>
                                    Upload a new {t('project.singular')}
                                </BoxTitle>
                            </CTAHeader>
                            <p className={cx('secondary', 'm-t-10', 'm-b-40')}>
                                Enter a {t('project.singular').toLowerCase()}
                                &apos;s basic information and upload documents
                            </p>
                            <div>
                                <Button
                                    $variant="secondary"
                                    onClick={() =>
                                        navigate(route('projectCreate'))
                                    }
                                    className={cx(
                                        'd-flex',
                                        'align-items-center',
                                        'g-5'
                                    )}
                                >
                                    <Icon name="plusSmall" />
                                    Upload {t('project.singular')}
                                </Button>
                            </div>
                        </BoxRightColumn>
                    </GrayBox>
                    <GrayBox>
                        <BoxLeftColumn>
                            <CTAIconContainer>
                                <CTAIcon name="pieChart" />
                            </CTAIconContainer>
                        </BoxLeftColumn>
                        <BoxRightColumn>
                            <CTAHeader>
                                <BoxTitle>
                                    Create {t('assessment.singular')}
                                </BoxTitle>
                            </CTAHeader>
                            <p className={cx('secondary', 'm-t-10', 'm-b-40')}>
                                Apply {t('model.singular').toLowerCase()} to a{' '}
                                {t('project.singular').toLowerCase()} and create{' '}
                                {t('report.plural').toLowerCase()}
                            </p>
                            <div>
                                <Button
                                    $variant="secondary"
                                    onClick={() =>
                                        navigate(route('assessmentCreate'))
                                    }
                                    className={cx(
                                        'd-flex',
                                        'align-items-center',
                                        'g-5'
                                    )}
                                >
                                    <Icon name="plusSmall" />
                                    Create {t('assessment.singular')}
                                </Button>
                            </div>
                        </BoxRightColumn>
                    </GrayBox>
                </Row>
                {assessmentsQuery.data && assessmentsQuery.data.length > 0 && (
                    <>
                        <Separator className={cx('m-t-50', 'm-b-50')} />
                        <h3 className="m-b-30">
                            Your {t('assessment.plural').toLowerCase()}
                        </h3>
                        <Row>
                            {assessmentsQuery.data.map((assessment) => (
                                <WhiteBox key={assessment.id}>
                                    <BoxLeftColumn>
                                        <AssessmentProgress
                                            assessment={assessment}
                                            radius={8.5}
                                            ringRadius={12.5}
                                            ringWidth={2}
                                        />
                                    </BoxLeftColumn>
                                    <BoxRightColumn>
                                        <AssessmentName>
                                            {assessment.name}
                                        </AssessmentName>
                                        <ProjectName>
                                            {assessment.project.name}
                                        </ProjectName>
                                        <ProjectCustomId>
                                            {assessment.project.customId}
                                        </ProjectCustomId>
                                        <div>
                                            <Button
                                                $variant="secondary"
                                                onClick={() =>
                                                    navigate(
                                                        route(
                                                            'assessment',
                                                            assessment.id
                                                        )
                                                    )
                                                }
                                            >
                                                View {t('assessment.singular')}
                                            </Button>
                                        </div>
                                    </BoxRightColumn>
                                </WhiteBox>
                            ))}
                        </Row>
                    </>
                )}
                <Separator className={cx('m-t-50', 'm-b-50')} />
                <h3 className="m-b-30">Platform settings</h3>
                <Row className="m-b-20">
                    <WhiteBox as={Link} to={route('profile')}>
                        <BoxLeftColumn>
                            <CTAIconContainer>
                                <CTAIcon name="userCircle" />
                            </CTAIconContainer>
                        </BoxLeftColumn>
                        <BoxRightColumn>
                            <BoxTitle>
                                <div
                                    className={cx(
                                        'd-flex',
                                        'justify-space-between',
                                        'primary'
                                    )}
                                >
                                    My profile
                                    <ChevronIcon name="chevronRight" />
                                </div>
                            </BoxTitle>
                            <p className={cx('secondary', 'm-t-10', 'm-b-40')}>
                                View and edit your profile information
                            </p>
                        </BoxRightColumn>
                    </WhiteBox>
                    <WhiteBox
                        as={Link}
                        to={route('team', currentTeamId ?? 'undefined')}
                    >
                        <BoxLeftColumn>
                            <CTAIconContainer>
                                <CTAIcon name="users" />
                            </CTAIconContainer>
                        </BoxLeftColumn>
                        <BoxRightColumn>
                            <BoxTitle>
                                <div
                                    className={cx(
                                        'd-flex',
                                        'justify-space-between',
                                        'primary'
                                    )}
                                >
                                    Team management
                                    <ChevronIcon name="chevronRight" />
                                </div>
                            </BoxTitle>
                            <p className={cx('secondary', 'm-t-10', 'm-b-40')}>
                                View team members and edit access permission
                                settings
                            </p>
                        </BoxRightColumn>
                    </WhiteBox>
                </Row>
            </Container>
        </Layout>
    )
}

import { useQuery } from '@tanstack/react-query'
import {
    Dropdown,
    DropdownTriggerProps,
    DropdownContent as PrestyledDropdownContent,
} from 'components/Dropdown'
import { AssessmentItem } from 'components/dropdowns/SearchDropdown/itemRenderers/AssessmentItem'
import { BaseItemSidePadding } from 'components/dropdowns/SearchDropdown/itemRenderers/BaseItem'
import { DataRoomItem } from 'components/dropdowns/SearchDropdown/itemRenderers/DataRoomItem'
import { DocumentItem } from 'components/dropdowns/SearchDropdown/itemRenderers/DocumentItem'
import { ModelItem } from 'components/dropdowns/SearchDropdown/itemRenderers/ModelItem'
import { ProjectItem } from 'components/dropdowns/SearchDropdown/itemRenderers/ProjectItem'
import { RecentSearchItem } from 'components/dropdowns/SearchDropdown/itemRenderers/RecentSearchItem'
import { ReportItem } from 'components/dropdowns/SearchDropdown/itemRenderers/ReportItem'
import { ReportTemplateItem } from 'components/dropdowns/SearchDropdown/itemRenderers/ReportTemplateItem'
import { FormFieldRoot, InputWrap } from 'components/Form/FormField'
import { TextField } from 'components/Form/TextField'
import { Icon as PrestyledIcon } from 'components/Icon'
import uniq from 'lodash-es/uniq'
import React, {
    ButtonHTMLAttributes,
    Fragment,
    KeyboardEvent,
    ReactNode,
    RefObject,
    useMemo,
    useRef,
    useState,
} from 'react'
import {
    AssessmentListItem,
    DataRoom,
    DataRoomDocumentWithRelations,
    Model,
    Project,
    ReportListItem,
    ReportTemplate,
} from 'silta-ai-backend'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { apiClient } from 'utils/clients'
import { addRecentSearch, retrieveRecentSearches } from 'utils/search'
import { nextParentSibling, prevParentSibling } from 'utils/traversing'
import { useDebouncedValue } from 'utils/useDebouncedValue'

const NoResultWrap = styled.div`
    align-items: center;
    color: ${themeVariables.colors.secondary};
    display: flex;
    justify-content: center;
    height: 40px;
    padding: 0 20px;
`

const Section = styled.section`
    margin: 0;
    padding: 0;

    & + & {
        margin-top: 16px;
    }

    > ul {
        list-style: none;
        padding: 0;
        margin: 8px 0 0;
    }
`

const SectionTitle = styled.div`
    color: ${themeVariables.colors.secondary};
    padding: 0 ${BaseItemSidePadding};
`

const DropdownContent = styled(PrestyledDropdownContent)`
    padding: 20px 0;
    max-height: 400px;
`

const SearchDropdownRoot = styled.div`
    ${FormFieldRoot} {
        width: 600px;
    }

    ${InputWrap} {
        border-color: transparent;
        background: transparent;
    }

    ${InputWrap} input {
        padding-left: 0;
    }
`

const Icon = styled(PrestyledIcon)`
    color: ${themeVariables.colors.secondary};
    width: 32px;
    height: 40px;

    svg {
        width: 24px;
        height: 24px;
    }
`

const SpinnerWrap = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    svg {
        width: 16px;
        height: 16px;
    }
`

interface TriggerProps extends DropdownTriggerProps {
    value: string
    setValue(value: string): void
    inputRef?: RefObject<HTMLInputElement>
    onKeyDown?(event: KeyboardEvent<HTMLInputElement>): void
}

function Trigger({
    inputRef,
    toggle,
    value,
    setValue,
    onKeyDown,
}: TriggerProps) {
    return (
        <TextField
            placeholder="Search"
            inputRef={inputRef}
            onChange={(e) => {
                setValue(e.target.value)

                toggle(true)
            }}
            onKeyDown={onKeyDown}
            onFocus={() => {
                toggle(true)
            }}
            type="text"
            value={value}
            prefixContent={<Icon name="search" />}
        />
    )
}

interface BaseSearchResultItem {
    id: string
}

type LineupItem<I extends BaseSearchResultItem = BaseSearchResultItem> = {
    key: string
    allowEmpty?: boolean
    fn(
        params: { search: string; pageSize?: number },
        options?: { signal?: AbortSignal }
    ): Promise<I[]>
    sectionTitle: string
    renderer(
        props: Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'value'> & {
            value: NoInfer<I>
        }
    ): ReactNode
}

interface SearchDropdownProps<L extends LineupItem> {
    lineup?: L[]
}

export const AssessmentsLineupItem: LineupItem<AssessmentListItem> = {
    key: 'assessments',
    fn: apiClient.getAssessments.bind(apiClient),
    sectionTitle: 'Assessments',
    renderer: AssessmentItem,
}

export const ProjectsLineupItem: LineupItem<Project> = {
    key: 'projects',
    fn: apiClient.getProjects.bind(apiClient),
    sectionTitle: 'Projects',
    renderer: ProjectItem,
}

export const DocumentsLineupItem: LineupItem<DataRoomDocumentWithRelations> = {
    key: 'documents',
    fn: apiClient.getDataRoomDocuments.bind(apiClient),
    sectionTitle: 'Documents',
    renderer: DocumentItem,
}

export const DataRoomsLineupItem: LineupItem<DataRoom> = {
    key: 'dataRooms',
    fn: apiClient.getDataRooms.bind(apiClient),
    sectionTitle: 'Data rooms',
    renderer: DataRoomItem,
}

export const ModelsLineupItem: LineupItem<Model> = {
    key: 'models',
    fn: apiClient.getModels.bind(apiClient),
    sectionTitle: 'Evaluation Models',
    renderer: ModelItem,
}

export const ReportsLineupItem: LineupItem<ReportListItem> = {
    key: 'reports',
    fn: apiClient.getReports.bind(apiClient),
    sectionTitle: 'Reports',
    renderer: ReportItem,
}

export const ReportTemplatesLineupItem: LineupItem<ReportTemplate> = {
    key: 'reportTemplates',
    fn: apiClient.getReportTemplates.bind(apiClient),
    sectionTitle: 'Report templates',
    renderer: ReportTemplateItem,
}

export const RecentSearchLineupItem: LineupItem = {
    allowEmpty: true,
    key: 'recentSearches',
    fn: ({ search }) => {
        if (search) {
            return Promise.resolve([])
        }

        try {
            return Promise.resolve(
                retrieveRecentSearches().map((id) => ({ id }))
            )
        } catch (e) {
            return Promise.resolve([])
        }
    },
    sectionTitle: 'Recent searches',
    renderer: RecentSearchItem,
}

export const RecentAssessmentsLineupItem: LineupItem<AssessmentListItem> = {
    allowEmpty: true,
    fn: async ({ search }, { signal } = {}) => {
        if (search) {
            return []
        }

        return apiClient.getAssessments({ pageSize: 3 }, { signal })
    },
    key: 'recentAssessments',
    renderer: AssessmentItem,
    sectionTitle: 'Recent assessments',
}

const defaultLineup: never[] = []

export function SearchDropdown<
    L extends LineupItem,
    I extends BaseSearchResultItem = L extends LineupItem<infer R>
        ? R
        : BaseSearchResultItem,
>({ lineup: lineupProp = defaultLineup }: SearchDropdownProps<L>) {
    const lineup = useMemo(
        () =>
            uniq([
                ...lineupProp,
                RecentSearchLineupItem,
                RecentAssessmentsLineupItem,
                AssessmentsLineupItem,
                ProjectsLineupItem,
                DocumentsLineupItem,
                DataRoomsLineupItem,
                ModelsLineupItem,
                ReportsLineupItem,
                ReportTemplatesLineupItem,
            ]),
        [lineupProp]
    )

    const [value, setValue] = useState('')

    const debouncedValue = useDebouncedValue(value.trim(), 250)

    const inputRef = useRef<HTMLInputElement>(null)

    const [searchResult, setSearchResult] = useState<
        Partial<Record<string, I[]>>
    >({})

    const searchQuery = useQuery({
        queryKey: ['search', debouncedValue],
        queryFn: async ({ signal }) => {
            let dirty = true

            let count = 0

            function setPartialResultWithReset(key: string, items: I[]) {
                if (signal.aborted) {
                    return
                }

                if (dirty) {
                    setSearchResult({
                        [key]: items,
                    })

                    dirty = false

                    return
                }

                setSearchResult((c) => ({
                    ...c,
                    [key]: items,
                }))
            }

            for (const item of lineup) {
                try {
                    const items = (
                        item.allowEmpty || debouncedValue
                            ? await item.fn(
                                  {
                                      search: debouncedValue,
                                      pageSize: item === lineup[0] ? 10 : 5,
                                  },
                                  { signal }
                              )
                            : []
                    ) as I[]

                    count += items.length

                    setPartialResultWithReset(item.key, items)
                } catch (e) {
                    //
                }
            }

            return count > 0
        },
    })

    const contentRef = useRef<HTMLDivElement>(null)

    function focusNext(currentElement: HTMLElement) {
        if (currentElement instanceof HTMLInputElement) {
            const el = contentRef.current?.querySelector('button') || null

            if (!el) {
                return
            }

            el.focus()

            return
        }

        if (!(currentElement instanceof HTMLButtonElement)) {
            return
        }

        const nextListElementSibling = nextParentSibling(currentElement, 'li')

        if (nextListElementSibling) {
            nextListElementSibling.querySelector('button')?.focus()

            return
        }

        const nextSectionElementSibling = nextParentSibling(
            currentElement,
            'section'
        )

        nextSectionElementSibling?.querySelector('button')?.focus()
    }

    function focusPrev(currentElement: HTMLElement) {
        if (!(currentElement instanceof HTMLButtonElement)) {
            return
        }

        const prevListElementSibling = prevParentSibling(currentElement, 'li')

        if (prevListElementSibling) {
            prevListElementSibling.querySelector('button')?.focus()

            return
        }

        const prevSectionElementSibling = prevParentSibling(
            currentElement,
            'section'
        )

        if (prevSectionElementSibling) {
            prevSectionElementSibling
                .querySelector('li:last-child')
                ?.querySelector('button')
                ?.focus()

            return
        }

        inputRef.current?.focus()
    }

    function onKeyDown(e: KeyboardEvent<HTMLElement>) {
        if (!(e.target instanceof HTMLElement)) {
            return
        }

        if (e.key === 'ArrowDown') {
            focusNext(e.target)

            e.preventDefault()
        } else if (e.key === 'ArrowUp') {
            focusPrev(e.target)

            if (!(e.target instanceof HTMLInputElement)) {
                e.preventDefault()
            }
        }
    }

    return (
        <SearchDropdownRoot>
            <Dropdown
                triggerProps={{ inputRef, setValue, value, onKeyDown }}
                trigger={Trigger}
            >
                {(dismiss) => (
                    <DropdownContent $minWidth="600px" ref={contentRef}>
                        {lineup.map(({ renderer: Renderer, ...item }) => {
                            const items = searchResult[item.key] || []

                            if (!items.length) {
                                return null
                            }

                            return (
                                <Fragment key={item.key}>
                                    <Section>
                                        <SectionTitle>
                                            {item.sectionTitle}
                                        </SectionTitle>
                                        <ul>
                                            {items.map((resultItem) => (
                                                <li key={resultItem.id}>
                                                    <Renderer
                                                        onKeyDown={onKeyDown}
                                                        onClick={() => {
                                                            if (
                                                                item.key ===
                                                                'recentSearches'
                                                            ) {
                                                                setValue(
                                                                    resultItem.id
                                                                )

                                                                inputRef.current?.focus()
                                                            } else {
                                                                dismiss()

                                                                if (value) {
                                                                    addRecentSearch(
                                                                        value
                                                                    )
                                                                }
                                                            }
                                                        }}
                                                        value={resultItem}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </Section>
                                </Fragment>
                            )
                        })}
                        {searchQuery.isLoading && (
                            <SpinnerWrap>
                                <Icon name="spinner" />
                            </SpinnerWrap>
                        )}
                        {searchQuery.data === false && (
                            <NoResultWrap>
                                0 results found for &ldquo;{value}&rdquo;
                            </NoResultWrap>
                        )}
                    </DropdownContent>
                )}
            </Dropdown>
        </SearchDropdownRoot>
    )
}

import {
    ProjectsLineupItem,
    SearchDropdown,
} from 'components/dropdowns/SearchDropdown'
import { Layout } from 'components/Layout'
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { DataRoomDocument } from 'silta-ai-backend'
import { useRequiredParam } from 'utils/misc'
import {
    useTeamPermissionsForResourceQuery,
    useIsFetchingAssessmentsForProject,
    useProjectQuery,
    invalidateDataRoomDocumentsQuery,
} from 'utils/queries'
import {
    useDeleteDocument,
    useDeleteProject,
    useIsDeletingDocument,
} from '../utils/mutations'
import { route } from '../utils/routes'
import { AccessDeniedHandler } from './AccessDeniedHandler'
import { DotdotdotButton } from './DotdotdotButton'
import { Dropdown, DropdownContent } from './Dropdown'
import { EntityProperties, EntityProps } from './EntityProperties'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from './Form/Select/SelectItemRenderer'
import { HorizontalLoadingIndicator } from './HorizontalLoadingIndicator'
import { Icon } from './Icon'
import { ListTopBar } from './ListTopBar'
import { confirmModal } from './modals/ConfirmationModal'
import { useEditProjectModal } from './modals/EditProjectModal'
import {
    ShowSidebarProvider,
    Sidebar,
    SidebarHost,
    SidebarSection,
    useShowSidebar,
} from './Sidebar'
import { SidebarToggle } from './SidebarToggle'
import { BreadcrumbsToolbar } from './Toolbar/BreadcrumbsToolbar'
import { useShareResourceModal } from './modals/ShareResourceModal'
import { UserAvatar } from './UserAvatar'
import { UserAvatarPlaceholder } from './UserAvatarPlaceholder'
import {
    useProjectFocusedDocument,
    useUpdateProjectFocusedDocument,
} from '../state/ProjectFocusedDocument.state'
import { DocumentSidebarContent } from './DocumentSidebarContent'

const LayoutInner = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;

    ${SidebarHost} {
        flex-grow: 1;
    }
`

const projectsSearchDropdown = <SearchDropdown lineup={[ProjectsLineupItem]} />

export function ProjectPageLayout() {
    const [displaySidebar, setDisplaySidebar] = useState(false)
    const focusedDocument = useProjectFocusedDocument()
    const updateFocusedDocument = useUpdateProjectFocusedDocument()

    const projectId = useRequiredParam('id')

    const { t } = useTranslation()

    const projectQuery = useProjectQuery({ projectId })

    const { isFetching, data: project } = projectQuery

    const deleteProject = useDeleteProject(projectId)

    const isFetchingAssessments = useIsFetchingAssessmentsForProject(projectId)

    const isDeletingDocument = useIsDeletingDocument()

    const triggerDeleteProject = useCallback(async () => {
        try {
            const confirmed = await confirmModal.pop({
                title: `Delete ${t('project.singular')}?`,
                content: `Are you sure you want to delete the ${t('project.singular').toLowerCase()} “${project?.name}”?`,
                confirmButtonText: `Delete ${t('project.singular')}`,
                cancelButtonText: 'Cancel',
            })
            if (confirmed) {
                deleteProject.mutate()
            }
        } catch (e) {
            // do nothing
        }
    }, [project, deleteProject])

    const triggerEditProject = useEditProjectModal(projectId)

    const { data: permission, isFetching: teamPermissionIsLoading } =
        useTeamPermissionsForResourceQuery({
            projectId,
        })

    const shareResouceModal = useShareResourceModal()

    const triggerShareResource = useCallback(() => {
        if (!project) {
            return
        }
        shareResouceModal(project.name, { projectId: project.id })
    }, [project, shareResouceModal])

    useEffect(() => {
        setDisplaySidebar(!!focusedDocument)
    }, [focusedDocument])

    return (
        <AccessDeniedHandler>
            <ShowSidebarProvider value={displaySidebar}>
                <Layout>
                    <LayoutInner>
                        <ListTopBar searchComponent={projectsSearchDropdown} />
                        <HorizontalLoadingIndicator
                            loading={
                                isFetching ||
                                isFetchingAssessments ||
                                isDeletingDocument ||
                                deleteProject.isPending ||
                                teamPermissionIsLoading
                            }
                        />
                        {!!project && (
                            <>
                                <BreadcrumbsToolbar
                                    currentPageName={
                                        project.customId ?? project.name
                                    }
                                    listPage={{
                                        name: `All ${t('project.plural').toLowerCase()}`,
                                        route: route('projects'),
                                        icon: 'grid',
                                    }}
                                    lhsAux={
                                        <>
                                            <Dropdown
                                                trigger={({ onClick }) => (
                                                    <DotdotdotButton
                                                        onClick={onClick}
                                                    />
                                                )}
                                            >
                                                {(dismiss) => (
                                                    <DropdownContent>
                                                        <SelectBasicItem
                                                            type="button"
                                                            disabled={
                                                                teamPermissionIsLoading ||
                                                                permission ===
                                                                    'Viewer'
                                                            }
                                                            onClick={() => {
                                                                dismiss()
                                                                triggerEditProject(
                                                                    project
                                                                )
                                                            }}
                                                        >
                                                            <SelectBasicItemIconWrap>
                                                                <Icon name="edit" />
                                                            </SelectBasicItemIconWrap>
                                                            Edit
                                                        </SelectBasicItem>
                                                        <SelectBasicItem
                                                            type="button"
                                                            disabled={
                                                                teamPermissionIsLoading ||
                                                                permission !==
                                                                    'Owner'
                                                            }
                                                            onClick={() => {
                                                                dismiss()
                                                                triggerShareResource()
                                                            }}
                                                        >
                                                            <SelectBasicItemIconWrap>
                                                                <Icon name="users" />
                                                            </SelectBasicItemIconWrap>
                                                            Manage permissions
                                                        </SelectBasicItem>
                                                        <SelectBasicItem
                                                            type="button"
                                                            disabled={
                                                                teamPermissionIsLoading ||
                                                                permission !==
                                                                    'Owner'
                                                            }
                                                            onClick={() => {
                                                                dismiss()
                                                                triggerDeleteProject()
                                                            }}
                                                        >
                                                            <SelectBasicItemIconWrap>
                                                                <Icon name="trash" />
                                                            </SelectBasicItemIconWrap>
                                                            Delete
                                                        </SelectBasicItem>
                                                    </DropdownContent>
                                                )}
                                            </Dropdown>
                                        </>
                                    }
                                    rhsAux={
                                        <SidebarToggle
                                            value={displaySidebar}
                                            onClick={() => {
                                                setDisplaySidebar((isOpen) => {
                                                    if (
                                                        isOpen &&
                                                        focusedDocument
                                                    ) {
                                                        updateFocusedDocument(
                                                            null
                                                        )
                                                    }
                                                    return !isOpen
                                                })
                                            }}
                                        />
                                    }
                                />
                                <Outlet />
                            </>
                        )}
                        {!isFetching && !project && <>Not found</>}
                    </LayoutInner>
                </Layout>
            </ShowSidebarProvider>
        </AccessDeniedHandler>
    )
}

const MainColumn = styled.div`
    flex-grow: 1;
`

interface ProjectInnerLayoutProps {
    projectId: string
    children?: ReactNode
    toolbars?: ReactNode
}

export function ProjectInnerLayout({
    projectId,
    children,
    toolbars,
}: ProjectInnerLayoutProps) {
    const displaySidebar = useShowSidebar()

    const { data: project = null } = useProjectQuery({ projectId })

    const focusedDocument = useProjectFocusedDocument()

    const deleteDocument = useDeleteDocument()

    const { t } = useTranslation()

    const triggerDeleteDocument = useCallback(
        async (doc: DataRoomDocument) => {
            try {
                const confirmed = await confirmModal.pop({
                    title: 'Delete Document?',
                    content: `Are you sure you want to delete document “${doc.originalFileName}”?`,
                    confirmButtonText: 'Delete Document',
                    cancelButtonText: 'Cancel',
                })
                if (confirmed) {
                    deleteDocument.mutate(
                        { documentId: doc.id, projectId },
                        {
                            onSuccess: () => {
                                invalidateDataRoomDocumentsQuery(
                                    project?.dataRoomId
                                )
                            },
                        }
                    )
                }
            } catch (e) {
                // do nothing
            }
        },
        [projectId, project]
    )

    const sidebarProperties = useMemo<EntityProps[]>(() => {
        return project
            ? ([
                  project.country
                      ? {
                            name: 'Country',
                            value: {
                                content: project.country,
                                icon: <Icon name="globe" />,
                            },
                        }
                      : undefined,
                  project.assignedTo
                      ? {
                            name: t('project.assignee'),
                            value: {
                                content: project.assignedTo.name || 'N/A',
                                icon: project.assignedTo.avatarBlobId ? (
                                    <UserAvatar
                                        size={22}
                                        avatarBlobId={
                                            project.assignedTo.avatarBlobId
                                        }
                                    />
                                ) : (
                                    <UserAvatarPlaceholder
                                        size={22}
                                        userName={project.assignedTo.name}
                                    />
                                ),
                            },
                        }
                      : undefined,
                  project.sector
                      ? {
                            name: 'Sector',
                            value: {
                                content: project.sector,
                                icon: <Icon name="parthenon" />,
                            },
                        }
                      : undefined,
                  {
                      name: t('project.dataRoom'),
                      value: {
                          content: (
                              <span className="no-wrap">
                                  {`${project.dataRoom._count.documents} documents`}
                              </span>
                          ),
                          icon: <Icon name="folder" />,
                      },
                  },
              ].filter(Boolean) as EntityProps[])
            : []
    }, [project])

    const { data: permission, isFetching: teamPermissionIsLoading } =
        useTeamPermissionsForResourceQuery({
            projectId,
        })

    if (!project) {
        return null
    }

    return (
        <>
            {toolbars}
            <SidebarHost>
                <MainColumn>{children}</MainColumn>
                {displaySidebar && (
                    <Sidebar>
                        {focusedDocument ? (
                            <DocumentSidebarContent
                                document={focusedDocument}
                                triggerDeleteDocument={triggerDeleteDocument}
                                canDeleteDocument={
                                    !teamPermissionIsLoading &&
                                    permission === 'Owner'
                                }
                            />
                        ) : (
                            <>
                                <SidebarSection title="Properties" emphasized>
                                    <EntityProperties
                                        entityProps={sidebarProperties}
                                    />
                                </SidebarSection>
                                <SidebarSection title="Organization">
                                    {project.dataRoom.billingOrg.name}
                                </SidebarSection>
                            </>
                        )}
                    </Sidebar>
                )}
            </SidebarHost>
        </>
    )
}

import { useState, useEffect } from 'react'
import { useCurrentUserQuery, resetAllQueries } from 'utils/queries'
import { getStoredTeam, storeCurrentTeam } from 'utils/team'
import { apiClient } from 'utils/clients'
import { useNavigate } from 'react-router'
import { route } from './routes'
import { useAccessDenied } from '../providers/AccessDeniedProvider'

export function useTeamSelection() {
    const { data: user, isFetching } = useCurrentUserQuery()
    const [currentTeamId, setCurrentTeamId] = useState<string | null>(null)
    const [currentOrganization, setCurrentOrganization] = useState<{
        id: string
        name: string
    } | null>(null)
    const navigate = useNavigate()
    const { setCustomAccessDeniedHandler, resetAccessDenied } =
        useAccessDenied()

    useEffect(() => {
        if (!user?.id || !user?.teamRoles) return
        const storedTeam = getStoredTeam(user.id)
        const validTeam =
            user.teamRoles.find((team) => team.teamId === storedTeam?.id)
                ?.team ?? user.teamRoles[0]?.team

        setCurrentTeamId(validTeam?.id ?? null)
        setCurrentOrganization(validTeam?.organization ?? null)
    }, [user?.id, user?.teamRoles])

    const switchTeam = async (teamId: string) => {
        if (!user?.id || !user?.teamRoles) return

        const selectedTeamRole = user.teamRoles.find(
            (team) => team.teamId === teamId
        )
        if (!selectedTeamRole) {
            console.error(`Team with id ${teamId} not found`)
            return
        }

        storeCurrentTeam(user.id, selectedTeamRole.team)
        setCurrentTeamId(selectedTeamRole.teamId)
        setCurrentOrganization(selectedTeamRole.team.organization)
        apiClient.setSelectedTeam(selectedTeamRole.teamId)
        setCustomAccessDeniedHandler(() => {
            navigate(route('home'))
        })
        resetAccessDenied()
        await resetAllQueries()
        setCustomAccessDeniedHandler(null)
    }

    return {
        currentTeamId,
        currentOrganization,
        teamRoles: user?.teamRoles,
        switchTeam,
        isFetchingTeamData: isFetching,
    }
}

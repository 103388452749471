import { AxiosError } from 'axios'
import { useRef } from 'react'
import { useIsMutating, useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router'
import { ApiError } from 'silta-ai-client'
import {
    Answer,
    Assessment,
    CategoryAssignee,
    CreateAnswerRequest,
    CreateCommentRequest,
    CreateOutcomeRequest,
    CreatePermissionRequest,
    CreateQuestionRequest,
    Model,
    ModelWithRelations,
    Outcome,
    OutcomeCriterion,
    Question,
    UpdateAnswerRequest,
    UpdateQuestionRequest,
    UpdateUserRequest,
} from 'silta-ai-backend'
import { useAssessmentDraft } from '../state/AssessmentDraft.state'
import { ProjectDraft } from '../types/projects'
import { apiClient } from './clients'
import {
    invalidateAnswerQuery,
    invalidateAssessmentQuery,
    invalidateDataRoomQuery,
    invalidateOutcomesQuery,
    invalidateProjectQuery,
    invalidateReportQuery,
    invalidateReportTemplateQuery,
    invalidateCurrentUserQuery,
    invalidateModelQuery,
    invalidateCategoryAssigneesQuery,
    invalidatePermissionsQuery,
    invalidateTeamInvitesQuery,
    invalidateTeamQuery,
    invalidateAnswerActivitiesQuery,
    invalidateOrganizationQuery,
} from './queries'
import { route } from './routes'
import { useAuth } from '../providers/AuthProvider'
import { useTeamSelection } from './useTeamSelection'
import { showToast, ToastType } from '../components/Toast/Toast'
import { openFaultyDocumentsModal } from '../components/modals/FaultyDocumentsModal'

function getErrorDesc(error: Error): string | undefined {
    return error instanceof AxiosError ? error.response?.data.error : undefined
}

/*
 * Model Outcomes
 */
export const useCreateOutcome = (transientId: string) =>
    useMutation({
        mutationKey: ['useCreateOutcome', transientId],
        mutationFn: async ({ outcome }: { outcome: CreateOutcomeRequest }) =>
            apiClient.createOutcome(outcome),
        onSuccess: ({ modelId }) => {
            invalidateModelQuery(modelId)
            invalidateOutcomesQuery(modelId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while creating outcome',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error creating outcome', error)
        },
    })

export const useIsOutcomeBeingCreated = (transientId: string): boolean =>
    useIsMutating({
        mutationKey: ['useCreateOutcome', transientId],
        exact: true,
    }) > 0

export const useUpdateOutcome = (outcomeId: string) =>
    useMutation({
        mutationKey: ['useUpdateOutcome', outcomeId],
        mutationFn: async (
            payload: Partial<Omit<CreateOutcomeRequest, 'modelId'>>
        ) => apiClient.updateOutcome(outcomeId, payload),
        onSuccess: (outcome) => {
            invalidateModelQuery(outcome.modelId)
            invalidateOutcomesQuery(outcome.modelId)
        },
        onError: (error) => {
            console.error('Error updating outcome', error)
            showToast({
                title: 'An error occurred while updating outcome',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
        },
    })

export const useIsOutcomeBeingUpdated = (outcomeId: string) =>
    useIsMutating({
        mutationKey: ['useUpdateOutcome', outcomeId],
        exact: true,
    }) > 0

export const useDeleteOutcome = (modelId: string, outcomeId: string) =>
    useMutation({
        mutationKey: ['useDeleteOutcome', outcomeId, modelId],
        mutationFn: async () => {
            await apiClient.deleteOutcome(outcomeId)
        },
        onSuccess: () => {
            invalidateModelQuery(modelId)
            invalidateOutcomesQuery(modelId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting outcome',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error deleting outcome', error)
        },
    })

export const useIsOutcomeBeingDeleted = (outcomeId: string): boolean =>
    useIsMutating({
        mutationKey: ['useDeleteOutcome', outcomeId],
        exact: false,
    }) > 0

export const useUpdateOutcomeOrder = (modelId: string) =>
    useMutation({
        mutationKey: ['useUpdateOutcomeOrder', modelId],
        mutationFn: async (directives: { id: string; sortOrder: number }[]) => {
            await apiClient.updateOutcomeOrder(directives)
        },
        onSuccess: () => {
            invalidateOutcomesQuery(modelId)
            invalidateModelQuery(modelId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating the order',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Updating outcome order failed', error)
        },
    })

export const useIsOutcomeOrderBeingUpdated = (modelId: string) =>
    useIsMutating({
        mutationKey: ['useUpdateOutcomeOrder', modelId],
        exact: true,
    }) > 0

export const useRemoveOutcome = () =>
    useMutation({
        mutationFn: async (outcome: Outcome) => {
            await apiClient.deleteOutcome(outcome.id)
            return outcome
        },
        onSuccess: (outcome) => {
            invalidateModelQuery(outcome.modelId)
            invalidateOutcomesQuery(outcome.modelId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while removing the outcome',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error removing outcome', error)
        },
    })

export const useUpdateOutcomes = () =>
    useMutation({
        mutationFn: async (outcomes: Outcome[]) => {
            const results = []
            for (const outcome of outcomes) {
                const result = await apiClient.updateOutcome(outcome.id, {
                    sortOrder: outcome.sortOrder,
                    color: outcome.color,
                    label: outcome.label,
                })
                results.push(result)
            }
            return results
        },
        onSuccess: (outcomes) => {
            const modelIds = outcomes.map((outcome) => outcome.modelId)
            modelIds.forEach((modelId) => {
                invalidateModelQuery(modelId)
            })
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating the outcomes',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error updating outcomes', error)
        },
    })

/*
 * Model Questions
 */

export const useCreateQuestion = () =>
    useMutation({
        mutationFn: async ({
            questionRequestData,
            criteria,
        }: {
            questionRequestData: CreateQuestionRequest
            criteria: OutcomeCriterion[]
        }) => {
            const newQuestion =
                await apiClient.createQuestion(questionRequestData)

            await Promise.all(
                criteria.map(async (criterion) => {
                    if (criterion.criterion) {
                        await apiClient.createOutcomeCriterion({
                            questionId: newQuestion.id,
                            outcomeId: criterion.outcomeId,
                            criterion: criterion.criterion,
                        })
                    }
                })
            )

            return newQuestion
        },
        onSuccess: ({ modelId }) => {
            invalidateModelQuery(modelId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while creating the question',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error creating question', error)
        },
    })

export const useUpdateQuestion = () =>
    useMutation({
        mutationFn: async ({
            question,
            questionRequestData,
            criteria,
        }: {
            question: ModelWithRelations['questions'][number]
            questionRequestData: UpdateQuestionRequest
            criteria: OutcomeCriterion[]
        }) => {
            // Update question
            const updatedQuestion = await apiClient.updateQuestion(
                question.id,
                {
                    content: questionRequestData.content,
                    sortOrder: questionRequestData.sortOrder,
                    category1: questionRequestData.category1 ?? undefined,
                    category2: questionRequestData.category2 ?? undefined,
                    hint: questionRequestData.hint ?? undefined,
                }
            )

            // Update outcome criteria
            await Promise.all(
                criteria.map(async (next) => {
                    const prev = question.criteria.find(
                        (criterion) => criterion.id === next.id
                    )

                    if (prev) {
                        if (!next.criterion) {
                            await apiClient.deleteOutcomeCriterion(prev.id)
                        } else if (prev.criterion !== next.criterion) {
                            await apiClient.updateOutcomeCriterion(prev.id, {
                                criterion: next.criterion,
                            })
                        }

                        return
                    }

                    if (next.criterion) {
                        await apiClient.createOutcomeCriterion({
                            questionId: question.id,
                            outcomeId: next.outcomeId,
                            criterion: next.criterion,
                        })
                    }
                })
            )

            return updatedQuestion
        },
        onSuccess: (question) => {
            invalidateModelQuery(question.modelId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating the question',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error updating question', error)
        },
    })

export const useDeleteQuestion = () =>
    useMutation({
        mutationFn: async (question: Question) => {
            await apiClient.deleteQuestion(question.id)
            return question
        },
        onSuccess: (question) => {
            invalidateModelQuery(question.modelId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the question',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error deleting question', error)
        },
    })

export const useCreateAssessment = () => {
    const draft = useAssessmentDraft()
    const navigate = useNavigate()

    return useMutation({
        mutationFn: async () => {
            if (!draft.projectId || !draft.name) {
                throw new Error('Assessment draft is not complete')
            }
            const assessment = await apiClient.createAssessment({
                projectId: draft.projectId,
                modelId: draft.modelId,
                name: draft.name,
                sourceQuestionIds: draft.filteredQuestions.map(
                    (question) => question.id
                ),
                precedentDataRoomIds: draft.precedentDataRoomIds,
                assignedToId: draft.assignedToId,
            })

            return assessment
        },
        onSuccess: async (assessment) => {
            try {
                await apiClient.startAssessment(assessment.id)
            } catch (err: unknown) {
                const error = err as ApiError

                if (
                    error.response?.data?.details?.cause ===
                    'CONTAINS_FAULTY_DOCUMENTS'
                ) {
                    // open the modal and run again if user confirms
                    const runWithFaultyDocuments =
                        await openFaultyDocumentsModal(
                            error.response.data.details
                                .projectDataRoomDocumentsWithIssues,
                            error.response.data.details
                                .precedentsDataRoomDocumentsWithIssues,
                            'assessment'
                        )
                    if (runWithFaultyDocuments) {
                        try {
                            await apiClient.startAssessment(
                                assessment.id,
                                undefined,
                                true
                            )
                        } catch (err) {
                            showToast({
                                title: 'An error occurred while starting the assessment',
                                type: ToastType.Error,
                                desc: getErrorDesc(error),
                            })
                        }
                    }
                } else {
                    showToast({
                        title: 'An error occurred while starting the assessment',
                        type: ToastType.Error,
                        desc: getErrorDesc(error),
                    })
                }
            }

            navigate(route('assessment', assessment.id))
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while creating the assessment',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error creating assessment', error)
        },
    })
}

export const useUpdateAssessment = () => {
    return useMutation({
        mutationKey: ['useUpdateAssessment'],
        mutationFn: ({ id, name }: { id: string; name: string }) =>
            apiClient.updateAssessment(id, { name }),
        onSuccess: (assessment) => {
            invalidateAssessmentQuery(assessment.id)
        },
        onError: (error) => {
            console.error('Error updating assessment', error)
        },
    })
}

export const useDeleteAnswer = () =>
    useMutation({
        mutationFn: async (answer: Answer) => {
            await apiClient.deleteAnswer(answer.id)
            return answer
        },
        onSuccess: (answer) => {
            invalidateAssessmentQuery(answer.assessmentId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the answer',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Deleting answer failed', error)
        },
    })

export const useRunAnswer = () =>
    useMutation({
        mutationFn: async ({
            answer,
            startWithFaultyDocuments,
        }: {
            answer: Answer
            startWithFaultyDocuments?: boolean
        }) => {
            await apiClient.startAnswer(answer.id, startWithFaultyDocuments)

            return answer
        },
        onSuccess: (answer) => {
            invalidateAssessmentQuery(answer.assessmentId)
            invalidateAnswerQuery(answer.id)
            invalidateAnswerActivitiesQuery(answer.id)
        },
        onError: (error: ApiError) => {
            console.error('Running answer failed', error)
            if (
                error.response?.data?.details?.cause !==
                'CONTAINS_FAULTY_DOCUMENTS'
            ) {
                showToast({
                    title: 'An error occurred while running the answer',
                    type: ToastType.Error,
                    desc: getErrorDesc(error),
                })
            }
        },
    })

export const useRunAssessment = () =>
    useMutation({
        mutationFn: async ({
            assessment,
            answerIds,
            startWithFaultyDocuments,
        }: {
            assessment: Assessment
            answerIds?: string[]
            startWithFaultyDocuments?: boolean
        }) => {
            await apiClient.startAssessment(
                assessment.id,
                answerIds,
                startWithFaultyDocuments
            )

            return assessment
        },
        onSuccess: (assessment) => {
            invalidateAssessmentQuery(assessment.id)
        },
        onError: (error: ApiError) => {
            console.error('Running assessment failed', error)
            showToast({
                title: 'An error occurred while running the assessment',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
        },
    })

export const useDeleteAssessment = () => {
    const navigate = useNavigate()

    return useMutation({
        mutationFn: async (assessment: Assessment) => {
            await apiClient.deleteAssessment(assessment.id)

            return assessment
        },
        onSuccess: () => {
            navigate(
                {
                    pathname: route('assessments'),
                    search: window.location.search,
                },
                {
                    replace: true,
                }
            )
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the assessment',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Deleting assessment failed', error)
        },
    })
}

export const useCreateDataRoom = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useCreateDataRoom'],
        mutationFn: async (name: string) => {
            return apiClient.createDataRoom({ name })
        },
        onSuccess: (dataRoom) => {
            navigate(route('dataRoom', dataRoom.id))
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while creating the precedent database',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error creating data room', error)
        },
    })
}

export const useUpdateDataRoom = () => {
    return useMutation({
        mutationKey: ['useUpdateDataRoom'],
        mutationFn: ({ id, name }: { id: string; name: string }) =>
            apiClient.updateDataRoom(id, { name }),
        onSuccess: (dataRoom) => {
            invalidateDataRoomQuery(dataRoom.id)
        },
        onError: (error) => {
            console.error('Error updating data room', error)
        },
    })
}

export const useCreateModel = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useCreateModel'],
        mutationFn: async (name: string) => {
            return apiClient.createModel({ name })
        },
        onSuccess: (model) => {
            navigate(route('model', model.id))
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while creating the evaluation model',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error creating model', error)
        },
    })
}

export const useUpdateModel = () => {
    return useMutation({
        mutationKey: ['useUpdateModel'],
        mutationFn: ({ id, name }: { id: string; name: string }) =>
            apiClient.updateModel(id, { name }),
        onSuccess: (model) => {
            invalidateModelQuery(model.id)
        },
        onError: (error) => {
            console.error('Error updating model', error)
        },
    })
}

export const useDeleteModel = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationFn: async (model: Model) => {
            await apiClient.deleteModel(model.id)
            return model
        },
        onSuccess: (model) => {
            navigate(route('models'))
            invalidateModelQuery(model.id)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the evaluation model',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Deleting answer failed', error)
        },
    })
}

export const useUploadModelFromCSV = (modelId: string) =>
    useMutation({
        mutationKey: ['useUploadModelFromCSV'],
        mutationFn: async (file: File) => {
            return apiClient.loadModelFromCSV(file, modelId)
        },
        onSuccess: () => {
            invalidateModelQuery(modelId)
            invalidateOutcomesQuery(modelId)
        },
        onError: (error) => {
            showToast({
                title: 'Error uploading CSV',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error uploading CSV', error)
        },
    })

export const useCreateAnswer = (assessmentId: string) =>
    useMutation({
        mutationKey: ['useCreateAnswer', assessmentId],
        mutationFn: async (
            request: Omit<CreateAnswerRequest, 'assessmentId'>
        ) => {
            const answer = await apiClient.createAnswer({
                ...request,
                assessmentId,
            })

            return answer
        },
        onSuccess: async (answer) => {
            invalidateAssessmentQuery(answer.assessmentId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while creating the answer',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Question creation failed', error)
        },
    })

export const useIsAnswerBeingCreated = (assessmentId: string) =>
    useIsMutating({
        mutationKey: ['useCreateAnswer', assessmentId],
        exact: true,
    }) > 0

export const useUpdateAnswer = (answer: Answer) =>
    useMutation({
        mutationKey: ['useUpdateAnswer', answer.id],
        mutationFn: async (request: UpdateAnswerRequest) => {
            await apiClient.updateAnswer(answer.id, request)

            return answer
        },
        onSuccess: (answer) => {
            invalidateAssessmentQuery(answer.assessmentId)
            invalidateAnswerQuery(answer.id)
            invalidateAnswerActivitiesQuery(answer.id)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating the answer',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Failed to set review status', error)
        },
    })

export const useIsAnswerBeingUpdated = (answerId: string) =>
    useIsMutating({
        mutationKey: ['useUpdateAnswer', answerId],
        exact: true,
    }) > 0

export const useCreateProject = () => {
    const navigate = useNavigate()

    return useMutation({
        mutationFn: async (project: ProjectDraft) => {
            const newProject = await apiClient.createProject(project)
            return newProject
        },
        onSuccess: (project) => {
            navigate(route('project', project.id))
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while creating the project',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Project creation failed', error)
        },
    })
}

export const useUpdateProject = (projectId: string) => {
    return useMutation({
        mutationKey: ['useUpdateProject', projectId],
        mutationFn: async ({ project }: { project: ProjectDraft }) => {
            return apiClient.updateProject(projectId, project)
        },
        onSuccess: (project) => {
            invalidateProjectQuery(project.id)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating the project',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Project update failed', error)
        },
    })
}

export const useDeleteProject = (projectId: string) => {
    const navigate = useNavigate()

    return useMutation({
        mutationKey: ['useDeleteProject', projectId],
        mutationFn: async () => {
            await apiClient.deleteProject(projectId)
        },
        onSuccess: () => {
            navigate(route('projects'))
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the project',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Deleting project failed', error)
        },
    })
}

export const useDeleteDataRoom = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useDeleteDataRoom'],
        mutationFn: async (dataRoomId: string) => {
            await apiClient.deleteDataRoom(dataRoomId)
        },
        onSuccess: () => {
            navigate(route('dataRooms'))
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the precedent database',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Deleting data room failed', error)
        },
    })
}

export const useDeleteDocument = () =>
    useMutation({
        mutationKey: ['useDeleteDocument'],
        mutationFn: async ({
            documentId,
            projectId,
            dataRoomId,
        }: {
            documentId: string
            projectId?: string
            dataRoomId?: string
        }) => {
            await apiClient.deleteDataRoomDocument(documentId)

            return {
                documentId,
                projectId,
                dataRoomId,
            }
        },
        onSuccess: ({ projectId, dataRoomId }) => {
            if (projectId) {
                invalidateProjectQuery(projectId)
            }
            if (dataRoomId) {
                invalidateDataRoomQuery(dataRoomId)
            }
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the document',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Failed to delete document', error)
        },
    })

export const useIsDeletingDocument = () =>
    useIsMutating({
        mutationKey: ['useDeleteDocument'],
        exact: true,
    }) > 0

export const useAcceptTermsAndConditions = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useAcceptTermsAndConditions'],
        mutationFn: async () => {
            await apiClient.acceptTermsAndConditions()
        },
        onSuccess: async () => {
            await invalidateCurrentUserQuery()
            navigate(route('home'))
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while accepting the terms and conditions',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error accepting terms and conditions', error)
        },
    })
}

export const useCreateUser = () => {
    const { signin } = useAuth()
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useCreateUser'],
        mutationFn: async ({
            name,
            email,
            password,
            signupCode,
            avatar,
        }: {
            name: string
            email: string
            password: string
            signupCode: string
            avatar?: File
        }) => {
            await apiClient.createUser({
                name,
                email,
                password,
                signupCode,
            })
            await signin({ email, password })
            if (avatar) {
                await apiClient.uploadAvatar(avatar)
            }
        },
        onSuccess: () => {
            navigate(route('home'))
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while creating your account',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error creating user', error)
        },
    })
}

export const useCreatePasswordResetRequest = () => {
    return useMutation({
        mutationKey: ['useCreatePasswordResetRequest'],
        mutationFn: async (email: string) => {
            return apiClient.requestPasswordReset(email)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while sending the password reset email',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error sending password reset email', error)
        },
    })
}

export const useSetNewPassword = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useSetNewPassowrd'],
        mutationFn: async ({
            token,
            newPassword,
        }: {
            token: string
            newPassword: string
        }) => {
            return apiClient.setNewPassword(token, newPassword)
        },
        onSuccess: () => {
            navigate(route('login'))
            showToast({
                title: 'Your password has been changed.',
                desc: 'You can now sign in using your new password.',
                type: ToastType.Success,
                autoCloseAfter: 10,
            })
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while saving your new password',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error saving new password', error)
        },
    })
}

export const useUpdateMyProfile = () => {
    return useMutation({
        mutationKey: ['useUpdateMyProfile'],
        mutationFn: async (request: UpdateUserRequest) => {
            return apiClient.updateUser(request)
        },
        onSuccess: () => {
            invalidateCurrentUserQuery()
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating your profile',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error updating user', error)
        },
    })
}
export const useUploadAvatar = () => {
    return useMutation({
        mutationKey: ['useUploadAvatar'],
        mutationFn: async (file: File) => {
            return apiClient.uploadAvatar(file)
        },
        onSuccess: () => {
            invalidateCurrentUserQuery()
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while uploading your profile picture',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error uploading avatar', error)
        },
    })
}

export const useUploadReportTemplate = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useUploadReportTemplate'],
        mutationFn: async (file: File) => {
            const arrayBuffer = await file.arrayBuffer()
            const blob = new Blob([arrayBuffer], { type: file.type })
            return apiClient.uploadReportTemplate(blob, file.name)
        },
        onSuccess: (reportTemplate) => {
            navigate(route('reportTemplate', reportTemplate.id))
        },
        onError: (error: AxiosError) => {
            const noAipromptsError =
                (
                    error?.response?.data as unknown as {
                        error?: string
                        cause?: string
                    }
                )?.cause === 'NO_AI_PROMPTS'
            showToast({
                title: 'An error occurred while uploading the report template',
                type: ToastType.Error,
                desc: noAipromptsError
                    ? `The uploaded report template contains no prompts
                                for the AI. Prompts are marked by enclosing them
                                in square brackets. In reports generated from
                                the template, the prompts are replaced by the
                                AI's outputs. For example: [Describe in greater detail the specifics of the
                                location of the project.]`
                    : getErrorDesc(error),
                okLabel: noAipromptsError ? 'Understood' : undefined,
                autoCloseAfter: noAipromptsError ? 15 : undefined,
            })
            console.log(error)
        },
    })
}

export const useDeleteReportTemplate = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useDeleteReportTemplate'],
        mutationFn: async (reportTemplateId: string) => {
            await apiClient.deleteReportTemplate(reportTemplateId)
        },
        onSuccess: () => {
            navigate(route('reportTemplates'))
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the report template',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error deleting report template', error)
        },
    })
}

export const useUpdateReportTemplate = () => {
    return useMutation({
        mutationKey: ['useUpdateReportTemplate'],
        mutationFn: ({ id, name }: { id: string; name: string }) => {
            return apiClient.updateReportTemplate(id, { name })
        },
        onSuccess: (reportTemplate) => {
            invalidateReportTemplateQuery(reportTemplate.id)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating the report template',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error updating report template', error)
        },
    })
}

export const useCreateReport = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useCreateReport'],
        mutationFn: ({
            name,
            assessmentId,
            reportTemplateId,
        }: {
            name: string
            assessmentId: string
            reportTemplateId: string
        }) => {
            return apiClient.createReport({
                name,
                assessmentId,
                reportTemplateId,
            })
        },
        onSuccess: async (report) => {
            navigate(route('report', report.id))
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while creating the report',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error creating report', error)
        },
    })
}

export const useUpdateReport = () => {
    return useMutation({
        mutationKey: ['useUpdateReport'],
        mutationFn: ({ id, name }: { id: string; name: string }) => {
            return apiClient.updateReport(id, { name })
        },
        onSuccess: (report) => {
            invalidateReportQuery(report.id)
        },
        onError: (error) => {
            console.error('Error updating report', error)
        },
    })
}
export const useRunReport = () => {
    return useMutation({
        mutationKey: ['useRunReport'],
        mutationFn: ({
            id,
            startWithFaultyDocuments,
        }: {
            id: string
            startWithFaultyDocuments?: boolean
        }) => {
            return apiClient.startReport(id, startWithFaultyDocuments)
        },
        onSuccess: (report) => {
            invalidateReportQuery(report.id)
        },
        onError: (error: ApiError) => {
            console.error('Error running report', error)
            if (
                error.response?.data?.details?.cause !==
                'CONTAINS_FAULTY_DOCUMENTS'
            ) {
                showToast({
                    title: 'An error occurred while running the report',
                    type: ToastType.Error,
                    desc: getErrorDesc(error),
                })
            }
        },
    })
}

export const useDeleteReport = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useDeleteReport'],
        mutationFn: (reportId: string) => {
            return apiClient.deleteReport(reportId)
        },
        onSuccess: () => {
            navigate(route('reports'))
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the report',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error deleting report', error)
        },
    })
}

export const useUpsertCategoryAssignee = () => {
    return useMutation({
        mutationFn: async (categoryAssignee: CategoryAssignee) => {
            const newCategoryAssignee =
                await apiClient.upsertCategoryAssignee(categoryAssignee)
            return newCategoryAssignee
        },
        onSuccess: (data: CategoryAssignee) => {
            invalidateCategoryAssigneesQuery(data.assessmentId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while changing the category assignee',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Changing the category assignee failed', error)
        },
    })
}

export const useAcceptTeamInvite = () => {
    return useMutation({
        mutationKey: ['useAcceptTeamInvite'],
        mutationFn: async ({
            inviteId,
            userId,
        }: {
            inviteId: string
            userId: string
        }) => {
            await apiClient.acceptTeamInvite(inviteId)
            return { userId }
        },
        onSuccess: ({ userId }) => {
            invalidateCurrentUserQuery()
            invalidateTeamInvitesQuery({ userId })
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while accepting the team invite',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error accepting team invite', error)
        },
    })
}

export const useRejectTeamInvite = () => {
    return useMutation({
        mutationKey: ['useRejectTeamInvite'],
        mutationFn: async ({
            inviteId,
            userId,
        }: {
            inviteId: string
            userId: string
        }) => {
            await apiClient.deleteTeamInvite(inviteId)
            return { userId }
        },
        onSuccess: ({ userId }) => {
            invalidateCurrentUserQuery()
            invalidateTeamInvitesQuery({ userId })
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while rejecting the team invite',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error rejecting team invite', error)
        },
    })
}

export const useLeaveTeam = () => {
    return useMutation({
        mutationKey: ['useLeaveTeam'],
        mutationFn: async ({
            teamId,
            userId,
        }: {
            teamId: string
            userId: string
        }) => {
            await apiClient.removeUserFromTeam(teamId, userId)
            return { userId }
        },
        onSuccess: ({ userId }) => {
            invalidateCurrentUserQuery()
            invalidateTeamInvitesQuery({ userId })
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while leaving the team',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error leaving team', error)
        },
    })
}

export const useDeleteTeam = (orgId?: string) => {
    return useMutation({
        mutationKey: ['useDeleteTeam'],
        mutationFn: async ({ teamId }: { teamId: string }) => {
            await apiClient.deleteTeam(teamId)
            return { teamId }
        },
        onSuccess: ({ teamId }) => {
            invalidateCurrentUserQuery()
            invalidateOrganizationQuery(orgId)
            invalidateTeamQuery(teamId)
            showToast({
                title: 'Team deleted',
                type: ToastType.Success,
            })
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the team',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error deleting team', error)
        },
    })
}

export const useUpdateUserRoleInTeam = () => {
    return useMutation({
        mutationKey: ['useUpdateUserRoleInTeam'],
        mutationFn: async ({
            teamId,
            userId,
            role,
        }: {
            teamId: string
            userId: string
            role: 'Owner' | 'Member'
        }) => {
            await apiClient.updateUserRoleInTeam(teamId, userId, { role })
            return { teamId }
        },
        onSuccess: ({ teamId }) => {
            invalidateCurrentUserQuery()
            invalidateTeamQuery(teamId)
            invalidateTeamInvitesQuery({ teamId })
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating the user role in the team',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error updating user role in team', error)
        },
    })
}

export const useRemoveUserFromTeam = () => {
    return useMutation({
        mutationKey: ['useRemoveUserFromTeam'],
        mutationFn: async ({
            teamId,
            userId,
        }: {
            teamId: string
            userId: string
        }) => {
            await apiClient.removeUserFromTeam(teamId, userId)
            return { teamId }
        },
        onSuccess: ({ teamId }) => {
            invalidateCurrentUserQuery()
            invalidateTeamQuery(teamId)
            invalidateTeamInvitesQuery({ teamId })
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while removing the user from the team',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error removing user from team', error)
        },
    })
}

export const useUpdateTeam = () => {
    return useMutation({
        mutationKey: ['useUpdateTeam'],
        mutationFn: async ({ id, name }: { id: string; name: string }) => {
            await apiClient.updateTeam(id, { name })
            return { teamId: id }
        },
        onSuccess: ({ teamId }) => {
            invalidateCurrentUserQuery()
            invalidateTeamQuery(teamId)
            invalidateTeamInvitesQuery({ teamId })
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating the team',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error updating team', error)
        },
    })
}

export const useCreateTeam = () => {
    const { switchTeam } = useTeamSelection()
    const switchTeamRef = useRef(switchTeam)
    if (switchTeamRef.current !== switchTeam) {
        switchTeamRef.current = switchTeam
    }
    return useMutation({
        mutationKey: ['useCreateTeam'],
        mutationFn: async ({ name }: { name: string }) => {
            return apiClient.createTeam({ name })
        },
        onSuccess: async (team) => {
            await invalidateCurrentUserQuery()
            // it's ugly, but it's the only way to do it
            setTimeout(() => {
                switchTeamRef.current(team.id)
            }, 0)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while creating the team',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error creating team', error)
        },
    })
}

export const useCreateTeamInvite = () => {
    return useMutation({
        mutationKey: ['useCreateTeamInvite'],
        mutationFn: async ({
            email,
            teamId,
        }: {
            email: string
            teamId: string
        }) => {
            await apiClient.createTeamInvite({ email, teamId, role: 'Member' })
            return { teamId }
        },
        onSuccess: ({ teamId }) => {
            invalidateCurrentUserQuery()
            invalidateTeamQuery(teamId)
            invalidateTeamInvitesQuery({ teamId })
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while inviting the team member',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error creating team invite', error)
        },
    })
}

export const useDeleteTeamInvite = () => {
    return useMutation({
        mutationKey: ['useDeleteTeamInvite'],
        mutationFn: async ({
            teamId,
            inviteId,
        }: {
            teamId: string
            inviteId: string
        }) => {
            await apiClient.deleteTeamInvite(inviteId)
            return { teamId }
        },
        onSuccess: ({ teamId }) => {
            invalidateCurrentUserQuery()
            invalidateTeamQuery(teamId)
            invalidateTeamInvitesQuery({ teamId })
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the invite',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error deleting team invite', error)
        },
    })
}

export const useCreatePermission = () => {
    return useMutation({
        mutationKey: ['useCreatePermission'],
        mutationFn: (request: CreatePermissionRequest) => {
            return apiClient.createPermission(request)
        },
        onSuccess: () => {
            invalidatePermissionsQuery()
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while creating the permission',
                desc: getErrorDesc(error),
                type: ToastType.Error,
            })
            console.error('Error creating permission', error)
        },
    })
}

export const useRemovePermission = () => {
    return useMutation({
        mutationKey: ['useRemovePermission'],
        mutationFn: async (permissionId: string) => {
            return apiClient.deletePermission(permissionId)
        },
        onSuccess: () => {
            invalidatePermissionsQuery()
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the permission',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error deleting permission', error)
        },
    })
}

export const useUpdatePermission = () => {
    return useMutation({
        mutationKey: ['useUpdatePermission'],
        mutationFn: ({
            permissionId,
            role,
        }: {
            permissionId: string
            role: 'Owner' | 'Editor' | 'Viewer'
        }) => {
            return apiClient.updatePermission(permissionId, { role })
        },
        onSuccess: () => {
            invalidatePermissionsQuery()
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating the permission',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error updating permission', error)
        },
    })
}

export const useCreateAnswerComment = () => {
    return useMutation({
        mutationKey: ['useCreateAnswerComment'],
        mutationFn: (request: CreateCommentRequest) => {
            return apiClient.createComment(request)
        },
        onSuccess: (activity) => {
            invalidateAnswerActivitiesQuery(activity.answerId)
            showToast({
                title: 'Comment added!',
                type: ToastType.Success,
            })
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while creating the comment',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error creating comment', error)
        },
    })
}

export const useUpdateAnswerComment = () => {
    return useMutation({
        mutationKey: ['useUpdateAnswerComment'],
        mutationFn: ({
            commentId,
            comment,
        }: {
            commentId: string
            comment: string
        }) => {
            return apiClient.updateComment(commentId, { comment })
        },
        onSuccess: (activity) => {
            invalidateAnswerActivitiesQuery(activity.answerId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating the comment',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error updating comment', error)
        },
    })
}

export const useDeleteAnswerComment = () => {
    return useMutation({
        mutationKey: ['useDeleteAnswerComment'],
        mutationFn: async ({
            commentId,
            answerId,
        }: {
            commentId: string
            answerId: string
        }) => {
            await apiClient.deleteComment(commentId)
            return { answerId }
        },
        onSuccess: ({ answerId }) => {
            invalidateAnswerActivitiesQuery(answerId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while deleting the comment',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error deleting comment', error)
        },
    })
}

export const useUpdateUserRoleInOrganization = () => {
    return useMutation({
        mutationKey: ['useUpdateUserRoleInOrganization'],
        mutationFn: async ({
            orgId,
            userId,
            role,
        }: {
            orgId: string
            userId: string
            role: 'Owner' | 'Member'
        }) => {
            await apiClient.updateUserRoleInOrganization(orgId, userId, {
                role,
            })
            return { orgId }
        },
        onSuccess: ({ orgId }) => {
            invalidateCurrentUserQuery()
            invalidateOrganizationQuery(orgId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating the user role in the organization',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error updating user role in organization', error)
        },
    })
}

export const useRemoveUserFromOrganization = () => {
    return useMutation({
        mutationKey: ['useRemoveUserFromOrganization'],
        mutationFn: async ({
            orgId,
            userId,
        }: {
            orgId: string
            userId: string
        }) => {
            await apiClient.removeUserFromOrganization(orgId, userId)
            return { orgId }
        },
        onSuccess: ({ orgId }) => {
            invalidateCurrentUserQuery()
            invalidateOrganizationQuery(orgId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while removing the user from the organization',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error removing user from organization', error)
        },
    })
}

export const useUpdateOrganization = () => {
    return useMutation({
        mutationKey: ['useUpdateOrganization'],
        mutationFn: async ({ id, name }: { id: string; name: string }) => {
            await apiClient.updateOrganization(id, { name })
            return { orgId: id }
        },
        onSuccess: ({ orgId }) => {
            invalidateCurrentUserQuery()
            invalidateOrganizationQuery(orgId)
        },
        onError: (error) => {
            showToast({
                title: 'An error occurred while updating the organization',
                type: ToastType.Error,
                desc: getErrorDesc(error),
            })
            console.error('Error updating organization', error)
        },
    })
}

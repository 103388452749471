import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { Layout } from '../components/Layout'
import { useCropImageModal } from '../components/modals/CropImageModal'
import {
    useAcceptTeamInvite,
    useLeaveTeam,
    useRejectTeamInvite,
    useUpdateMyProfile,
    useUploadAvatar,
} from '../utils/mutations'
import { useCurrentUserQuery, useTeamInvitesQuery } from '../utils/queries'
import { Button } from '../components/Button'
import { Separator } from '../components/Separator'
import { Icon } from '../components/Icon'
import { UserAvatar } from '../components/UserAvatar'
import { UserAvatarPlaceholder } from '../components/UserAvatarPlaceholder'
import { BaseButton } from '../components/BaseButton'
import { TextField } from '../components/Form/TextField'
import { confirmModal } from '../components/modals/ConfirmationModal'
import { useChangePasswordModal } from '../components/modals/ChangePasswordModal'
import { useAuth } from '../providers/AuthProvider'
import { Table } from '../components/Table'
import { Badge } from '../components/Badge'
import { Dropdown, DropdownContent } from '../components/Dropdown'
import { DotdotdotButton } from '../components/DotdotdotButton'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from '../components/Form/Select/SelectItemRenderer'
import { TableDataLoading } from '../components/TableDataLoading'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { TeamOrInvite } from '../utils/team'
import { LoadMoreButton } from '../components/LoadMoreButton'

const Container = styled.div`
    padding: 0 40px;
`

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ProfileDataContainer = styled.div`
    display: grid;
    grid-template-columns: 248px 1fr;
    gap: 40px;

    .input-container {
        max-width: 240px;
    }
`

const FieldValue = styled.span`
    line-height: 42px;
`

const TeamAcceptRejectContainer = styled.div`
    display: flex;
    gap: 5px;
`

const TeamOptionsDropdownContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

export function ProfilePage() {
    const { session } = useAuth()
    const [isEditMode, setIsEditMode] = useState(false)
    const userQuery = useCurrentUserQuery()
    const {
        data: teamInvites,
        isLoading: isLoadingTeamInvites,
        isFetchingNextPage: isLoadingMoreTeamInvites,
        hasNextPage: hasMoreTeamInvites,
        fetchNextPage: fetchMoreTeamInvites,
    } = useTeamInvitesQuery({ userId: session?.userId || '' })

    const teamsAndIvites = useMemo<TeamOrInvite[]>(() => {
        if (!teamInvites || !userQuery?.data) {
            return []
        }
        const mappedTeams = userQuery.data.teamRoles
            .map((teamRole) => {
                return {
                    teamId: teamRole.teamId,
                    teamName: teamRole.team.name,
                    organization: teamRole.team.organization.name,
                    type: 'team' as const,
                    role: teamRole.role,
                }
            })
            .sort((a, b) => a.teamName.localeCompare(b.teamName))
        const mappedInvites = teamInvites
            .map((invite) => ({
                id: invite.id,
                teamId: invite.teamId,
                teamName: invite.team.name,
                organization: invite.team.organization.name,
                type: 'invite' as const,
                role: invite.role,
            }))
            .sort((a, b) => a.teamName.localeCompare(b.teamName))
        return [...mappedInvites, ...mappedTeams]
    }, [teamInvites, userQuery])

    const user = userQuery?.data
    const [newUserName, setNewUserName] = useState(user?.name)
    const updateMyProfile = useUpdateMyProfile()
    const cropiamgeModal = useCropImageModal()
    const uploadAvatar = useUploadAvatar()
    const triggerAvatarUpload = useCallback(() => {
        const fileInput = document.createElement('input')
        fileInput.type = 'file'
        fileInput.accept = '.jpg, .jpeg, .png'
        fileInput.click()
        fileInput.onchange = async (e) => {
            const file = (e.target as HTMLInputElement)?.files?.[0]
            if (file) {
                const fileUrl = URL.createObjectURL(file)
                await cropiamgeModal({
                    imageUrl: fileUrl,
                    onCrop: async (file) => {
                        await uploadAvatar.mutateAsync(file)
                        setIsEditMode(false)
                    },
                })
            }
        }
    }, [])
    const acceptTeamInvite = useAcceptTeamInvite()
    const rejectTeamInvite = useRejectTeamInvite()
    const leaveTeam = useLeaveTeam()

    const openChangePasswordModal = useChangePasswordModal()

    useEffect(() => {
        setNewUserName(user?.name)
    }, [user?.name])

    return (
        <Layout>
            <Container>
                <h1 className={cx('m-t-100', 'm-b-40')}>Profile</h1>

                <HeaderContainer>
                    <h3 className="m-b-0">User</h3>
                    {isEditMode ? (
                        <div
                            className={cx(
                                'd-flex',
                                'align-items-center',
                                'justify-content-center',
                                'g-5'
                            )}
                        >
                            <Button
                                $variant="secondary"
                                onClick={() => setIsEditMode(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={
                                    !newUserName ||
                                    newUserName === user?.name ||
                                    updateMyProfile.isPending
                                }
                                onClick={() => {
                                    updateMyProfile.mutate(
                                        {
                                            name: newUserName,
                                        },
                                        {
                                            onSuccess: () => {
                                                setIsEditMode(false)
                                            },
                                        }
                                    )
                                }}
                            >
                                {updateMyProfile.isPending
                                    ? 'Saving...'
                                    : 'Save'}
                            </Button>
                        </div>
                    ) : (
                        <Button
                            $variant="secondary"
                            className={cx(
                                'd-flex',
                                'align-items-center',
                                'justify-content-center',
                                'g-5'
                            )}
                            onClick={() => setIsEditMode(true)}
                        >
                            <Icon name="edit" />
                            Edit
                        </Button>
                    )}
                </HeaderContainer>
                <Separator className={cx('m-t-10', 'm-b-40')} />

                <ProfileDataContainer>
                    <span className="emphasized">Profile picture</span>
                    <div className={cx('d-flex', 'align-items-center', 'g-20')}>
                        {user?.avatarBlobId ? (
                            <UserAvatar
                                avatarBlobId={user?.avatarBlobId || ''}
                                size={160}
                            />
                        ) : (
                            <UserAvatarPlaceholder
                                userName={user?.name || ''}
                                size={160}
                            />
                        )}
                        {isEditMode && (
                            <div
                                className={cx('d-flex', 'flex-column', 'g-20')}
                            >
                                <Button
                                    $variant="secondary"
                                    className={cx(
                                        'd-flex',
                                        'g-5',
                                        'align-items-center'
                                    )}
                                    onClick={triggerAvatarUpload}
                                >
                                    <Icon name="upload" />
                                    Upload photo
                                </Button>
                                <BaseButton
                                    disabled={
                                        updateMyProfile.isPending ||
                                        !user?.avatarBlobId
                                    }
                                    onClick={() => {
                                        confirmModal.pop({
                                            title: 'Remove photo',
                                            content:
                                                'Are you sure you want to remove your photo?',
                                            confirmButtonText: 'Remove',
                                            cancelButtonText: 'Cancel',
                                            onResolve: () => {
                                                updateMyProfile.mutate(
                                                    {
                                                        avatarBlobId: null,
                                                    },
                                                    {
                                                        onSuccess: () => {
                                                            setIsEditMode(false)
                                                        },
                                                    }
                                                )
                                            },
                                        })
                                    }}
                                >
                                    Remove photo
                                </BaseButton>
                            </div>
                        )}
                    </div>
                    <span className={cx('align-self-center', 'emphasized')}>
                        Email
                    </span>
                    <div>
                        {isEditMode ? (
                            <TextField
                                containerClassName="input-container"
                                value={user?.email}
                                disabled={true}
                                title="Email editing will be available soon"
                            />
                        ) : (
                            <FieldValue>{user?.email}</FieldValue>
                        )}
                    </div>
                    <span className={cx('align-self-center', 'emphasized')}>
                        Name
                    </span>
                    <div>
                        {isEditMode ? (
                            <TextField
                                containerClassName="input-container"
                                value={newUserName}
                                onChange={(e) => setNewUserName(e.target.value)}
                            />
                        ) : (
                            <FieldValue>{user?.name}</FieldValue>
                        )}
                    </div>
                    <span className={cx('align-self-center', 'emphasized')}>
                        Password
                    </span>
                    <span>
                        <Button
                            $variant="secondary"
                            onClick={openChangePasswordModal}
                        >
                            Change Password
                        </Button>
                    </span>
                </ProfileDataContainer>
                <div className="m-t-50">
                    <h3 className="m-b-5">Teams</h3>
                    <p className="secondary">
                        You are a member of the following teams.
                    </p>
                </div>
                <div className="m-b-40">
                    <HorizontalLoadingIndicator
                        loading={
                            leaveTeam.isPending ||
                            rejectTeamInvite.isPending ||
                            acceptTeamInvite.isPending ||
                            isLoadingTeamInvites ||
                            isLoadingMoreTeamInvites
                        }
                    />
                    <Table
                        columns={[
                            {
                                title: 'Team',
                                key: 'teamName',
                                render: (row) => {
                                    return (
                                        <div>
                                            {row.teamName}
                                            {row.type === 'invite' && (
                                                <>
                                                    &nbsp;{' '}
                                                    <Badge $variant="default">
                                                        Pending
                                                    </Badge>
                                                </>
                                            )}
                                        </div>
                                    )
                                },
                                width: 'minmax(min-content, 20%)',
                            },
                            { title: 'Role', key: 'role' },
                            { title: 'Organization', key: 'organization' },
                            {
                                key: 'buttons',
                                render: (row) => {
                                    return row.type === 'invite' ? (
                                        <TeamAcceptRejectContainer>
                                            <Button
                                                className="no-wrap"
                                                disabled={
                                                    rejectTeamInvite.isPending ||
                                                    acceptTeamInvite.isPending
                                                }
                                                onClick={() =>
                                                    acceptTeamInvite.mutate({
                                                        inviteId: row.id!,
                                                        userId: user?.id || '',
                                                    })
                                                }
                                            >
                                                {acceptTeamInvite.isPending
                                                    ? 'Accepting...'
                                                    : 'Accept invitation'}
                                            </Button>
                                            <Button
                                                $variant="secondary"
                                                className="no-wrap"
                                                disabled={
                                                    rejectTeamInvite.isPending ||
                                                    acceptTeamInvite.isPending
                                                }
                                                onClick={() =>
                                                    rejectTeamInvite.mutate({
                                                        inviteId: row.id!,
                                                        userId: user?.id || '',
                                                    })
                                                }
                                            >
                                                {rejectTeamInvite.isPending
                                                    ? 'Rejecting...'
                                                    : 'Reject invitation'}
                                            </Button>
                                        </TeamAcceptRejectContainer>
                                    ) : (
                                        <TeamOptionsDropdownContainer>
                                            <Dropdown
                                                trigger={({ onClick }) => (
                                                    <DotdotdotButton
                                                        onClick={onClick}
                                                        flip
                                                    />
                                                )}
                                            >
                                                {(dismiss) => (
                                                    <DropdownContent>
                                                        <SelectBasicItem
                                                            type="button"
                                                            onClick={() => {
                                                                dismiss()
                                                                confirmModal.pop(
                                                                    {
                                                                        title: 'Leave team',
                                                                        content: `Are you sure you want to leave the team ${row.teamName}?`,
                                                                        confirmButtonText:
                                                                            'Leave',
                                                                        cancelButtonText:
                                                                            'Cancel',
                                                                        onResolve:
                                                                            () => {
                                                                                leaveTeam.mutate(
                                                                                    {
                                                                                        teamId: row.teamId,
                                                                                        userId:
                                                                                            user?.id ||
                                                                                            '',
                                                                                    }
                                                                                )
                                                                            },
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <SelectBasicItemIconWrap>
                                                                <Icon name="signOut" />
                                                            </SelectBasicItemIconWrap>
                                                            Leave team
                                                        </SelectBasicItem>
                                                    </DropdownContent>
                                                )}
                                            </Dropdown>
                                        </TeamOptionsDropdownContainer>
                                    )
                                },
                            },
                        ]}
                        data={teamsAndIvites}
                    />
                    {(isLoadingTeamInvites || userQuery.isLoading) && (
                        <TableDataLoading />
                    )}
                    {hasMoreTeamInvites && (
                        <LoadMoreButton
                            isLoading={isLoadingMoreTeamInvites}
                            onClick={fetchMoreTeamInvites}
                        />
                    )}
                </div>
            </Container>
        </Layout>
    )
}
